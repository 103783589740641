export default {
    white: '#ffffff',
    cobaltBright: '#0050D3',
    cobaltBrightRGB: '0,80,211',
    cobalt: '#0B439F',
    cobaltMd: '#85A1CF',
    cobaltLt: '#DBE3F1',
    cobaltDk: '#002D70',
    cobaltDkRGB: '0,45,112',
    blueLight: '#E7EEF7',
    navy: '#0F3482',
    green: '#00A63E',
    greenMd: '#7FD29E',
    greenMdRGB: '127, 210, 158',
    greenLt: '#D9F2E2',
    salmon: '#DB6058',
    salmonDk: '#C65049',
    salmonMd: '#EDAFAB',
    salmonLt: '#FAE7E6',
    black: '#222222',
    blackRGB: '34, 34, 34',
    smoke: '#6A6A6A',
    clouds: '#DDDDDD',
    error: '#B3433B',
    errorRGB: '179, 67, 59',
    mint: '#B4E2C5', // TODO check style guide, similar color already?
    error2: '#9D2235',
    pageBackground: '#edeeed',
    yellow: '#FFD278',
    yellowLt: '#ffecb9',
    yellowDk: '#ffb92b',
    storm: '#8E9499',
};
