import { createSelector } from 'reselect';

const getSupportOrdersState = state => state.supportOrders;

export const getSupportCCAPRecords = createSelector(
    getSupportOrdersState,
    state => state.ccap || [],
);

export const getSupportOrders = createSelector(
    getSupportOrdersState,
    state => state.orders || [],
);

export const getChildSupportCases = createSelector(
    getSupportOrders,
    (orders) => {
        if (!orders || orders.length === 0) {
            return [];
        }

        const sorted = [...orders].sort(({ createdAt: createA }, { createdAt: createB }) => {
            if (createA < createB) {
                return 1;
            }

            if (createA === createB) {
                return 0;
            }

            return -1;
        });

        const [recent] = sorted;
        const { createdAt, caseData } = recent;

        return caseData.map(courtCase => ({ ...courtCase, updated: createdAt }));
    },
);

export const getCSOCFormState = createSelector(
    getSupportOrdersState,
    state => state.form,
);

export const getCSOCPDFDownloadLink = createSelector(
    getCSOCFormState,
    formState => formState.PDFDownloadLink,
);

export const getCSOCFormData = createSelector(
    getCSOCFormState,
    formState => formState.data,
);

export const getFilledCSOCFormPdfs = createSelector(
    getCSOCFormState,
    formState => formState.filledFormPdfs.csoc || [],
);

export const getChildSupportOrderCount = createSelector(
    getChildSupportCases,
    cases => cases?.length || 0,
);
