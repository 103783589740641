import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage } from '../selectors/i18n';

// https://medium.com/javascript-in-plain-english/making-your-react-app-multilingual-with-vanilla-javascript-6979937e7ede
export default (key, content, params) => {
    const language = useSelector(getLanguage);
    const [sourceContent, setSourceContent] = useState();

    useEffect(() => {
        if (language) setSourceContent(content[language]);
    }, [content, language]);

    if (!sourceContent) return '';

    let i18nKey;
    if (typeof key !== 'object') i18nKey = sourceContent[key];
    else if (key.length === 2) i18nKey = sourceContent[key[0]][key[1]];
    else if (key.length === 3) i18nKey = sourceContent[key[0]][key[1]][key[2]];
    else if (key.length === 4) i18nKey = sourceContent[key[0]][key[1]][key[2]][key[3]];

    // we conditionally send in empty key's in some cases, so we need to have them fail gracefully
    if (!i18nKey) return false;

    if (params || params === 0) {
        if (typeof params !== 'object') {
            i18nKey = i18nKey.replace(/\{0\}/g, params);
        } else {
            for (let i = 0; i < params.length; i += 1) {
                i18nKey = i18nKey.replace(`{${i}}`, params[i]);
            }
        }
    }
    //

    if (typeof i18nKey !== 'string') {
        return i18nKey;
    }

    // Parse choice patterns
    const choiceRegex = /{choice[a-zA-Z0-9{}\s<>=|#]+}/g;
    const choicesRegex = /#[<>=0-9a-zA-Z|\s]+#/g;

    /* eslint-disable */
    if (choiceRegex.exec(i18nKey)) {
        for (const choicePattern of i18nKey.match(choiceRegex)) {
            const decisionMaker = parseInt(
                choicePattern.replace(choicesRegex, '')
                    .replace('{choice', '')
                    .replace('}', '')
                    .trim(),
                10
            );

            const choices = choicePattern.match(choicesRegex)[0]
                .replace(/#/g, '');

            const operators = choices.match(/[<>=]+/g);
            const numbers = choices.match(/[0-9]+/g).map(num => parseInt(num, 10));
            const words = choices.match(/[a-zA-Z]+/g);

            let indexToUse = 0;

            for (let i = 0; i < words.length; i++) {
                switch (operators[i]) {
                    case '<': indexToUse = numbers[i] < decisionMaker ? i : indexToUse; break;
                    case '>': indexToUse = numbers[i] > decisionMaker ? i : indexToUse; break;
                    case '<=': indexToUse = numbers[i] <= decisionMaker ? i : indexToUse; break;
                    case '>=': indexToUse = numbers[i] >= decisionMaker ? i : indexToUse; break;
                    case '=': indexToUse = numbers[i] === decisionMaker ? i : indexToUse; break;
                    default: indexToUse = numbers[i] === decisionMaker ? i : indexToUse; break;
                }
            }

            i18nKey = i18nKey.replace(choicePattern, words[indexToUse]);
        }
    }

    //

    return i18nKey;
};
