import WebClient from '../utils/web-client';
import UserTypes from '../action-types/user';
import { getUserFormData } from '../selectors/user';
import { getLanguage } from '../selectors/i18n';
import { getFilledFormPdfs as getCrimRecordPdfs } from '../selectors/crimrecord';
import { getFilledFormPdfs as getEvictionPdfs } from '../selectors/eviction';
import { getFormattedDate } from '../utils/date-utils';
import { sendCustomEvent } from '../utils/ga';

const preparePhoneNumber = phone => phone.replace(/[()-\s]*/g, '');

const searchRecordsBegin = () => ({
    type: UserTypes.SEARCH_RECORDS_BEGIN,
});

const searchRecordsSuccess = payload => ({
    type: UserTypes.SEARCH_RECORDS_SUCCESS,
    payload,
});

const searchRecordsError = errorMsg => ({
    type: UserTypes.SEARCH_RECORDS_ERROR,
    payload: { errorMsg },
});

export const searchRecords = onError => (
    async (dispatch, getState) => {
        const user = getUserFormData(getState());

        const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            dob: getFormattedDate(user.dob, 'yyyy-MM-dd'),
        };
        if (user.gender) payload.gender = user.gender;
        if (user.middleInitial) payload.middleInitial = user.middleInitial;
        if (user.v3Token) payload.v3Token = user.v3Token;
        if (user.v2Token) payload.v2Token = user.v2Token;

        dispatch(searchRecordsBegin());

        try {
            const response = await WebClient.post('/users/search/ccap', payload);
            dispatch(searchRecordsSuccess(response.data));
        } catch (error) {
            sendCustomEvent('search-records-error', { error: error.toString() });
            dispatch(searchRecordsError(error));
            if (onError) onError();
        }
    }
);

export const setUserAccountInfo = accountInfo => ({
    type: UserTypes.SET_USER_ACCOUNT_INFO,
    payload: { accountInfo },
});

export const setUserBasicInfo = formData => ({
    type: UserTypes.SET_USER_BASIC_INFO,
    payload: { formData },
});

export const setUserIdentification = idValues => ({
    type: UserTypes.SET_USER_IDENTIFICATION,
    payload: { idValues },
});

const createUserAccountBegin = () => ({
    type: UserTypes.CREATE_USER_ACCOUNT_BEGIN,
});
const createUserAccountSuccess = () => ({
    type: UserTypes.CREATE_USER_ACCOUNT_SUCCESS,
});
const createUserAccountError = errorMsg => ({
    type: UserTypes.CREATE_USER_ACCOUNT_ERROR,
    payload: { errorMsg },
});

export const createUserAccount = (onSuccess, onError) => (
    async (dispatch, getState) => {
        const user = getUserFormData(getState());
        const language = getLanguage(getState());

        const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            dob: user.dob,
            language,
        };
        if (user.gender) payload.gender = user.gender;
        if (user.income) payload.income = user.income;
        if (user.race) payload.race = Array.isArray(user.race) ? user.race : [user.race];
        if (user.middleInitial) payload.middleInitial = user.middleInitial;
        if (user.phone) payload.phone = user.phone;
        if (user.email) payload.email = user.email;

        // associate the new user in the backend with any forms that have been generated for them so far
        const filledFormPdfs = getCrimRecordPdfs(getState()).concat(getEvictionPdfs(getState()));
        if (filledFormPdfs.length) {
            const formIds = filledFormPdfs.map(filledForm => filledForm.id);
            payload.formIds = formIds;
        }

        dispatch(createUserAccountBegin());

        try {
            await WebClient.post('/users', payload);
            dispatch(createUserAccountSuccess());
            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(createUserAccountError(error));
            if (onError) onError();
        }
    }
);

const updateUserAccountBegin = () => ({
    type: UserTypes.UPDATE_USER_ACCOUNT_BEGIN,
});
const updateUserAccountSuccess = userData => ({
    type: UserTypes.UPDATE_USER_ACCOUNT_SUCCESS,
    payload: { userData },
});
const updateUserAccountError = errorMsg => ({
    type: UserTypes.UPDATE_USER_ACCOUNT_ERROR,
    payload: { errorMsg },
});

export const updateUserAccount = (userData, onSuccess, onError) => (
    async (dispatch, getState) => {
        dispatch(updateUserAccountBegin());
        const { id } = getUserFormData(getState());

        const payload = Object.assign(
            // replace any empty values in userData with null
            ...Object.entries(userData)
                .map(([key, value]) => (value === '' ? { [key]: null } : { [key]: value })),
        );

        if (userData.phone) {
            payload.phone = preparePhoneNumber(userData.phone);
        }

        try {
            await WebClient.patch(`/users/${id}`, payload);
            dispatch(updateUserAccountSuccess(payload));
            // if the user has included gender information in their profile, run a DOT search on save
            if (userData.gender && userData.gender !== '') {
                dispatch(searchRecords());
            }
            if (onSuccess) onSuccess();
        } catch (error) {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 409)) {
                errorMsg = 'Error: Data Conflict';
                if (onError) onError('conflictError');
            } else {
                errorMsg = 'Error: Unknown';
                if (onError) onError('genericError');
            }
            dispatch(updateUserAccountError(errorMsg));
        }
    }
);

const deleteUserAccountBegin = () => ({
    type: UserTypes.DELETE_USER_ACCOUNT_BEGIN,
});
const deleteUserAccountSuccess = () => ({
    type: UserTypes.DELETE_USER_ACCOUNT_SUCCESS,
});
const deleteUserAccountError = errorMsg => ({
    type: UserTypes.DELETE_USER_ACCOUNT_ERROR,
    payload: { errorMsg },
});

export const deleteUserAccount = (onSuccess, onError) => (
    async (dispatch) => {
        dispatch(deleteUserAccountBegin());

        try {
            await WebClient.delete('/user');
            dispatch(deleteUserAccountSuccess());
            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(deleteUserAccountError(error));
            if (onError) onError();
        }
    }
);

const getFilledFormsBegin = () => ({
    type: UserTypes.GET_FILLED_FORMS_BEGIN,
});

const getFilledFormsSuccess = filledForms => ({
    type: UserTypes.GET_FILLED_FORMS_SUCCESS,
    payload: { filledForms },
});

const getFilledFormsError = errorMsg => ({
    type: UserTypes.GET_FILLED_FORMS_ERROR,
    payload: { errorMsg },
});

export const getFilledForms = (onSuccess, onError) => (
    async (dispatch, getState) => {
        const user = getUserFormData(getState());

        dispatch(getFilledFormsBegin());

        try {
            const { data } = await WebClient.get(`/users/${user.id}/forms`);
            dispatch(getFilledFormsSuccess(data));
            if (onSuccess) onSuccess();
        } catch (error) {
            // a user may just not have any filled forms, so ignore 404s
            if (error.response && (error.response.status !== 404)) {
                dispatch(getFilledFormsError(error));
                if (onError) onError();
            }
        }
    }
);
