import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';

import useI18n from '../../utils/use-i18n';
import ContentEnglish from './i18n/en';
import ContentSpanish from './i18n/es';

const content = {
    en: ContentEnglish,
    es: ContentSpanish,
};

const InterrupterModal = ({
    classes, isOpen, onClose, href,
}) => (
    <Dialog
        open={isOpen}
        aria-labelledby={useI18n('modalTitle', content)}
        classes={{
            container: classes.modalWrapper,
            paper: classes.modalPaper,
        }}
    >
        <IconButton
            aria-label={useI18n('fingerModalClose', content)}
            className={classes.modalCloseButton}
            onClick={onClose}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle style={{ paddingLeft: 60, paddingTop: 45 }} disableTypography>
            <Typography variant="h2">{useI18n('modalTitle', content)}</Typography>
        </DialogTitle>
        <DialogContent className={classes.modalContentWrapper}>
            <div className="row no-gutters pb-4 mt-2">
                <div className="col">
                    <Typography variant="body2">
                        {useI18n('modalContent', content)}
                    </Typography>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-around w-100">
                <Button
                    className={`m-8 ${classes.buttonShadow} ${classes.modalButton} ${classes.cancelButton}`}
                    classes={{
                        label: classes.buttonLabel,
                    }}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.blueButtonRippleChildPulsate,
                        },
                    }}
                    onClick={onClose}
                >
                    {useI18n('cancel', content)}
                </Button>
                <Button
                    className={`m-8 text-center ${classes.buttonShadow} ${classes.modalButton} ${classes.proceedButton}`}
                    TouchRippleProps={{
                        classes: {
                            childPulsate: classes.blueButtonRippleChildPulsate,
                        },
                    }}
                    onClick={onClose}
                    target="_blank"
                    href={href}
                >
                    {useI18n('proceed', content)}
                </Button>
            </div>
        </DialogContent>
    </Dialog>
);

InterrupterModal.propTypes = {
    classes: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(combinedStyles)(InterrupterModal);
