import { createTheme } from '@material-ui/core/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
const breakpointObject = createTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistancy
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
});
const theme = createTheme({
    breakpoints: {
        values: {
            ...breakpointObject.breakpoints.values,
        },
    },
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 630,
            },
        },
        MuiDialogTitle: {
            root: {
                fontWeight: 700,
                fontSize: 24,
            },
        },
    },
    customButtons: {
        textTransform: 'none',
        fontWeight: 700,
        fontSize: 18,
        minHeight: 50,
        borderRadius: 5,
        lineHeight: 1,
        [breakpointObject.breakpoints.down('lg')]: {
            fontSize: 17,
            minHeight: 44,
        },
        [breakpointObject.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    customShadow: '0px 1px 3px 0px rgba(0,0,0,0.1)',
    typography: {
        useNextVariants: true,
        fontFamily: 'sofia-pro, sans-serif;',
        color: Colors.black,
        h1: {
            fontWeight: 700,
            fontSize: 34,
            letterSpacing: -0.5,
            [breakpointObject.breakpoints.down('lg')]: {
                fontSize: 30,
            },
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 27,
            },
        },
        h2: {
            fontWeight: 700,
            fontSize: 24,
            [breakpointObject.breakpoints.down('lg')]: {
                fontSize: 22,
            },
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 20,
            },
        },
        h3: {
            fontWeight: 700,
            fontSize: 20,
            [breakpointObject.breakpoints.down('lg')]: {
                fontSize: 19,
            },
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 18,
            },
        },
        h4: {
            fontWeight: 700,
            fontSize: 18,
            letterSpacing: 1,
            textTransform: 'uppercase',
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 17,
            },
        },
        h5: {
            fontSize: 16,
            letterSpacing: 1,
            textTransform: 'uppercase',
            [breakpointObject.breakpoints.down('lg')]: {
                fontSize: 15,
            },
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        body1: {
            fontSize: 16,
            color: Colors.smoke,
            [breakpointObject.breakpoints.down('lg')]: {
                fontSize: 15,
            },
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        body2: {
            lineHeight: '1.3em',
            fontSize: 18,
            [breakpointObject.breakpoints.down('lg')]: {
                fontSize: 17,
            },
            [breakpointObject.breakpoints.down('sm')]: {
                fontSize: 16,
            },
        },
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: Colors.cobalt,
        },
        secondary: {
            main: Colors.salmon,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.green,
        },
        background: {
            default: Colors.pageBackground,
        },
    },
});

export default theme;
