const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    CHECK_TOKEN_BEGIN: true,
    CHECK_TOKEN_SUCCESS: true,
    CHECK_TOKEN_ERROR: true,
    LOGIN_BEGIN: true,
    LOGIN_SUCCESS: true,
    LOGIN_ERROR: true,
    LOGOUT: true,
    INITIALIZE_APP: true,
    NO_TOKEN: true,
    SETUP_NEW_USER_BEGIN: true,
    SETUP_NEW_USER_SUCCESS: true,
    REQUEST_RESET_BEGIN: true,
    REQUEST_RESET_SUCCESS: true,
    REQUEST_RESET_ERROR: true,
    RESET_BEGIN: true,
    RESET_SUCCESS: true,
    RESET_ERROR: true,
});
