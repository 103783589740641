import AuthToken from './maintain-auth-token';
import Session from './session';

const internals = {
    initializers: [AuthToken, Session],
};

export default (store) => {
    internals.initializers.forEach(init => init(store));
};
