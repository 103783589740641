import React, {
    Suspense,
    lazy,
    useLayoutEffect,
    useEffect,
    useState,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
    Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import withRoot from '../wiring/with-root';
import { isAuthenticated } from '../selectors/auth';
import InitializerUser from '../initializers/user';
import themer from '../styles/material-theme';
import InterrupterModal from '../components/interrupter-modal';

const AuthenticatedLayout = lazy(() => import('../layouts/authenticated'));
const MainLayout = lazy(() => import('../layouts/main'));

const Checking = lazy(() => import('./intake/components/checking'));
const ErrorSessionExpired = lazy(() => import('./errors/session-expired'));

const App = ({ store }) => {
    const location = useLocation();
    const isUserSignedIn = useSelector(isAuthenticated);

    const [offsiteHref, setOffsiteHref] = useState('');

    const closeInterrupterModal = useCallback(() => {
        setOffsiteHref('');
    }, []);

    useLayoutEffect(() => {
        if (isUserSignedIn) {
            InitializerUser(store);
        }
    }, [isUserSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTimeout(() => {
            function openInterrupter(e) {
                try {
                    const href = e.target.attributes.href.nodeValue;
                    if (href.startsWith('http') && !href.includes('amazonaws')) {
                        e.preventDefault();
                        e.stopPropagation();

                        setOffsiteHref(href);
                        return false;
                    }
                    return true;
                } catch {
                    return true;
                }
            }

            document.querySelectorAll('a').forEach((element) => {
                element.addEventListener('click', openInterrupter);
            });

            return () => {
                document.querySelectorAll('a').forEach((element) => {
                    element.removeEventListener('click', openInterrupter);
                });
            };
        }, 500);
    }, [location]);

    return (
        <Suspense fallback={<div />}>
            <InterrupterModal
                href={offsiteHref}
                isOpen={!!offsiteHref}
                onClose={closeInterrupterModal}
            />
            <Switch location={location}>
                <Route path="/signedin">
                    {isUserSignedIn ? (
                        <AuthenticatedLayout />
                    ) : (
                        <Redirect to={{ pathname: '/login', state: { from: location } }} />
                    )}
                </Route>
                <Route exact path="/error/sessionexpired">
                    <ErrorSessionExpired />
                </Route>
                <Route path="/checking">
                    <Checking to="/results" />
                </Route>
                <Route exact path="/">
                    {isUserSignedIn ? (
                        <Redirect to="/signedin/results" />
                    ) : (
                        <MainLayout />
                    )}
                </Route>
                <Route path="/results">
                    {isUserSignedIn ? (
                        <Redirect to="/signedin/results" />
                    ) : (
                        <MainLayout />
                    )}
                </Route>
                <Route path="/">
                    <MainLayout />
                </Route>
            </Switch>
        </Suspense>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default withRoot(App, themer);
