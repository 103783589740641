import { createSelector } from 'reselect';

const getAuthState = state => state.auth;

export const isAuthenticated = createSelector(
    getAuthState,
    authState => authState.isAuthenticated,
);

export const isNewUser = createSelector(
    getAuthState,
    authState => authState.isNewUser,
);

const getCredentials = createSelector(
    getAuthState,
    authState => authState.credentials,
);

export const getToken = createSelector(
    getCredentials,
    credentials => (credentials ? credentials.token : null),
);

export const getAuthError = createSelector(
    getAuthState,
    authState => authState.error,
);

export const getAuthErrorMessage = createSelector(
    getAuthState,
    authState => authState.errorMsg,
);

export const isRequestResetSuccessful = createSelector(
    getAuthState,
    authState => (authState.requestReset.completed && !authState.requestReset.error),
);

export const getRequestResetError = createSelector(
    getAuthState,
    authState => authState.requestReset.error,
);

export const isResetSuccessful = createSelector(
    getAuthState,
    authState => (authState.reset.completed && !authState.reset.error),
);

export const getResetError = createSelector(
    getAuthState,
    authState => authState.reset.error,
);
