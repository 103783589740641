import filter from 'lodash/filter';

import WebClient from '../utils/web-client';
import CriminalRecordTypes from '../action-types/crimrecord';
import {
    getCriminalRecordFormData,
    getConfirmedRemovableCCAPRecords,
    getConfirmedRemovableDOJRecords,
    getFilledCCAPFormPdfs,
    getFilledDOJFormPdfs,
    getEmailToSendForms,
    getDojPollingTimer,
} from '../selectors/crimrecord';
import { getUserId, getUserFormData, getPreviouslyFilledForms } from '../selectors/user';
import { getLanguage } from '../selectors/i18n';
import { getFormattedDate } from '../utils/date-utils';
import { getFilledForms } from './user';

const CCAP_CRIMINAL_RECORD_FORM_ID = 1;
const DOJ_CRIMINAL_RECORD_FORM_ID = 3;
const POLLING_INTERVAL = 1000 * 60 * 5; // 5 mins (in milliseconds)

const emailCriminalRecordFormBegin = () => ({
    type: CriminalRecordTypes.EMAIL_CRIMRECORD_FORM_BEGIN,
});
const emailCriminalRecordFormSuccess = (type, emailedTo, emailedAt) => ({
    type: CriminalRecordTypes.EMAIL_CRIMRECORD_FORM_SUCCESS,
    payload: { type, emailedTo, emailedAt },
});
const emailCriminalRecordFormError = errorMsg => ({
    type: CriminalRecordTypes.EMAIL_CRIMRECORD_FORM_ERROR,
    payload: { errorMsg },
});
export const emailCriminalRecordForm = (type, email, onSuccess) => (
    async (dispatch, getState) => {
        let filledForms;
        // TODO: Determine if this will ever be used for anything but WORCS. If it's not, then
        // the conditionals below probably aren't needed

        // if it's possible to hit this action as an unauthenticated user completing the CCAP flow then
        // look first in the crimrecord reducer, because it will have any 'new' forms created
        if (type === 'criminal') filledForms = getFilledCCAPFormPdfs(getState());
        if (type === 'worcs') filledForms = getFilledDOJFormPdfs(getState());

        // if there isn't any data found, then let's look to the user reducer and pull out the forms
        // that match the type we are working with
        if (filledForms.length === 0) {
            const previouslyFilledForms = getPreviouslyFilledForms(getState());
            filledForms = filter(previouslyFilledForms, { type });
        }
        const formIds = filledForms.map(f => f.id);

        const payload = {
            ids: formIds,
            email,
        };

        dispatch(emailCriminalRecordFormBegin());

        try {
            const { data } = await WebClient.post('/forms/filled/email', payload);
            dispatch(emailCriminalRecordFormSuccess(type, email, data.emailedAt));
            dispatch(getFilledForms(onSuccess));
        } catch (error) {
            dispatch(emailCriminalRecordFormError(error));
        }
    }
);

const searchDojRecordsBegin = () => ({
    type: CriminalRecordTypes.SEARCH_DOJ_RECORDS_BEGIN,
});

const searchDojRecordsSuccess = records => ({
    type: CriminalRecordTypes.SEARCH_DOJ_RECORDS_SUCCESS,
    payload: { records },
});

const searchDojRecordsSetTimer = timer => ({
    type: CriminalRecordTypes.SEARCH_DOJ_RECORDS_SET_TIMER,
    payload: { timer },
});

const searchDojRecordsError = errorMsg => ({
    type: CriminalRecordTypes.SEARCH_DOJ_RECORDS_ERROR,
    payload: { errorMsg },
});

export const checkDojRecordsSearch = () => (
    async (dispatch, getState) => {
        const userId = getUserId(getState());
        const prevPollingTimer = getDojPollingTimer(getState());

        if (prevPollingTimer) {
            clearTimeout(prevPollingTimer);
        }

        try {
            const { data } = await WebClient.get(`/users/${userId}/search/doj`);

            // if a search has not been started then there's nothing else we need to do
            if (data.status === 'NOT_STARTED') return;

            dispatch(searchDojRecordsBegin());

            if (data.status === 'COMPLETED') {
                // the search returned results finally
                dispatch(searchDojRecordsSuccess(data.results));
            } else {
                // the search hasn't found any results yet
                // set up a timer to poll for the results again later
                const pollingTimer = setTimeout(() => dispatch(checkDojRecordsSearch()), POLLING_INTERVAL);
                dispatch(searchDojRecordsSetTimer(pollingTimer));
            }
        } catch (error) {
            dispatch(searchDojRecordsError(error));
        }
    }
);

export const startDojRecordsSearch = () => (
    async (dispatch, getState) => {
        const userId = getUserId(getState());
        const prevPollingTimer = getDojPollingTimer(getState());

        if (prevPollingTimer) {
            clearTimeout(prevPollingTimer);
        }

        dispatch(searchDojRecordsBegin());

        try {
            await WebClient.post(`/users/${userId}/search/doj`);

            // now that we've kicked off the search set up polling to check for the results
            const pollingTimer = setTimeout(() => dispatch(checkDojRecordsSearch()), POLLING_INTERVAL);
            dispatch(searchDojRecordsSetTimer(pollingTimer));
        } catch (error) {
            // a search has already been started for the user
            if (error.response && (error.response.status === 403)) {
                // then check the status of the search
                dispatch(checkDojRecordsSearch());
            } else {
                dispatch(searchDojRecordsError(error));
            }
        }
    }
);

export const setCriminalRecordBasicInfo = formData => ({
    type: CriminalRecordTypes.SET_CRIMRECORD_BASIC_INFO,
    payload: { formData },
});

export const setCriminalRecordConfirmDone = (caseNumber, isConfirmed, source) => ({
    type: CriminalRecordTypes.SET_CRIMRECORD_CONFIRM,
    payload: {
        caseNumber,
        isConfirmed,
        source,
    },
});

export const setCriminalRecordConfirm = (currentRecord, isConfirmed, source) => (
    async (dispatch, getState) => {
        dispatch(setCriminalRecordConfirmDone(currentRecord.caseNumber, isConfirmed, source));
        const user = getUserFormData(getState());
        const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            dob: user.dob,
            county: currentRecord.county,
            source,
            isUser: isConfirmed,
            caseNumber: currentRecord.caseNumber,
        };
        try {
            await WebClient.post('/users/search/results', payload);
        // eslint-disable-next-line no-empty
        } catch (error) {} // swallow errors
    }
);
export const setCriminalRecordESignature = eSignature => ({
    type: CriminalRecordTypes.SET_CRIMRECORD_ESIGNATURE,
    payload: { eSignature },
});
export const setCriminalRecordSendToEmail = email => ({
    type: CriminalRecordTypes.SET_CRIMRECORD_SEND_TO_EMAIL,
    payload: { email },
});

const postCriminalRecordFormDownloadBegin = () => ({
    type: CriminalRecordTypes.POST_CRIMRECORD_FORM_DOWNLOAD_BEGIN,
});
const postCCAPCriminalRecordFormDownloadSuccess = (PDFDownloadLink, responseCourtInfo) => ({
    type: CriminalRecordTypes.POST_CCAP_CRIMRECORD_FORM_DOWNLOAD_SUCCESS,
    payload: { PDFDownloadLink, responseCourtInfo },
});
const postDOJCriminalRecordFormDownloadSuccess = PDFDownloadLink => ({
    type: CriminalRecordTypes.POST_DOJ_CRIMRECORD_FORM_DOWNLOAD_SUCCESS,
    payload: { PDFDownloadLink },
});
const postCriminalRecordFormDownloadError = errorMsg => ({
    type: CriminalRecordTypes.POST_CRIMRECORD_FORM_DOWNLOAD_ERROR,
    payload: { errorMsg },
});
const postCriminalRecordFormDownload = source => (
    async (dispatch, getState) => {
        const ccapFilledForms = getFilledCCAPFormPdfs(getState());
        const dojFilledForms = getFilledDOJFormPdfs(getState());
        const filledForms = source === 'ccap' ? ccapFilledForms : dojFilledForms;

        const payload = {
            ids: filledForms.map(f => f.id),
        };

        dispatch(postCriminalRecordFormDownloadBegin());

        try {
            const response = await WebClient.post('/forms/download', payload);

            if (source === 'ccap') {
                dispatch(postCCAPCriminalRecordFormDownloadSuccess(response.data.url, response.data.courts));
            } else {
                dispatch(postDOJCriminalRecordFormDownloadSuccess(response.data.url));
            }
        } catch (error) {
            dispatch(postCriminalRecordFormDownloadError(error));
        }
    }
);

const mailCriminalRecordFormBegin = () => ({
    type: CriminalRecordTypes.MAIL_CRIMRECORD_FORM_BEGIN,
});
const mailCriminalRecordFormSuccess = (formIds, mailedAt) => ({
    type: CriminalRecordTypes.MAIL_CRIMRECORD_FORM_SUCCESS,
    payload: { formIds, mailedAt },
});
const mailCriminalRecordFormError = errorMsg => ({
    type: CriminalRecordTypes.MAIL_CRIMRECORD_FORM_ERROR,
    payload: { errorMsg },
});
export const mailCriminalRecordForm = (onSuccess, onError) => (
    async (dispatch, getState) => {
        // This will always be CCAP since we can't mail DOJ forms for the user
        const filledForms = getFilledCCAPFormPdfs(getState());
        const userFormData = getCriminalRecordFormData(getState());
        const language = getLanguage(getState());
        const formIds = filledForms.map(f => f.id);

        const payload = {
            ids: formIds,
            email: userFormData.email,
            language,
        };

        dispatch(mailCriminalRecordFormBegin());

        try {
            await WebClient.post('/forms/filled/mail', payload);
            dispatch(mailCriminalRecordFormSuccess(formIds, new Date()));
            onSuccess();
        } catch (error) {
            dispatch(mailCriminalRecordFormError(error));
            onError();
        }
    }
);

const postCriminalRecordFormBegin = () => ({
    type: CriminalRecordTypes.POST_CRIMRECORD_FORM_BEGIN,
});
const postCCAPCriminalRecordFormSuccess = filledFormPdfs => ({
    type: CriminalRecordTypes.POST_CCAP_CRIMRECORD_FORM_SUCCESS,
    payload: { filledFormPdfs },
});
const postDOJCriminalRecordFormSuccess = filledFormPdfs => ({
    type: CriminalRecordTypes.POST_DOJ_CRIMRECORD_FORM_SUCCESS,
    payload: { filledFormPdfs },
});
const postCriminalRecordFormError = errorMsg => ({
    type: CriminalRecordTypes.POST_CRIMRECORD_FORM_ERROR,
    payload: { errorMsg },
});

const postCCAPCriminalRecordForm = async (userFormData, userId, removableConfirmedRecords, dispatch) => {
    const basePayload = {
        signerAddress: `${userFormData.address}, ${userFormData.city}, ${userFormData.state} ${userFormData.zip}`,
        phoneNumber: userFormData.phone,
        emailAddress: userFormData.email,
        address: userFormData.address,
        city: userFormData.city,
        state: userFormData.state,
        zip: userFormData.zip,
        userId,
    };

    if (userFormData.eSignature) {
        basePayload.signature = userFormData.eSignature;
    }

    const filledForms = await Promise.all(
        removableConfirmedRecords.map(async (record) => {
            const payload = {
                formData: {
                    ...basePayload,
                    county: record.county,
                    caseNumber: record.caseNumber,
                    // no plaintiff needs to be sent for removing criminal records as these are always the same
                    respondentFirst: record.defendant.firstName,
                    respondentMiddle: record.defendant.middleName,
                    respondentLast: record.defendant.lastName,
                    dob: userFormData.dob,
                    date: getFormattedDate(new Date(), 'MM/dd/yyyy'),
                },
            };

            const response = await WebClient.post(`/forms/${CCAP_CRIMINAL_RECORD_FORM_ID}/fill`, payload);
            return {
                ...response.data,
                createdAt: new Date(),
                year: record.filingDate.split('/').pop(),
                charge: record.charges[0].description,
                type: 'criminal',
            };
        }),
    );

    // create/update form ids
    dispatch(postCCAPCriminalRecordFormSuccess(filledForms));
};
const postDOJCriminalRecordForm = async (userFormData, userId, removableConfirmedRecords, dispatch) => {
    const basePayload = {
        address1: userFormData.address,
        address2: userFormData.apt,
        city: userFormData.city,
        state: userFormData.state,
        zip: userFormData.zip,
        userId,
    };

    if (userFormData.eSignature) {
        basePayload.signature = userFormData.eSignature;
    }

    const filledForms = await Promise.all(
        removableConfirmedRecords.map(async (record) => {
            const payload = {
                formData: {
                    ...basePayload,
                    county: record.county,
                    caseNumber: record.caseNumber,
                    firstName: record.defendant.firstName,
                    middleName: record.defendant.middleName,
                    lastName: record.defendant.lastName,
                },
            };
            const response = await WebClient.post(`/forms/${DOJ_CRIMINAL_RECORD_FORM_ID}/fill-doj`, payload);
            return {
                // DOJ has forms where CCAP does not because there can be multiple forms generated for a single case
                forms: response.data,
                createdAt: new Date(),
                year: record.filingDate.split('/').pop(),
                charge: record.charges[0].description,
                type: 'worcs',
            };
        }),
    );

    // flatten the array (one form per entry) so it matches the shape of other filledForm responses
    const flattenedFilledForms = [];
    filledForms.forEach(({ forms, ...formProps }) => {
        forms.forEach((form) => {
            flattenedFilledForms.push({
                ...form,
                ...formProps,
            });
        });
    });

    dispatch(postDOJCriminalRecordFormSuccess(flattenedFilledForms));
};
export const postCriminalRecordForm = (source, onSuccess, onError) => (
    async (dispatch, getState) => {
        const userFormData = getCriminalRecordFormData(getState());
        const userId = getUserId(getState());

        const fullFormData = getUserFormData(getState());
        userFormData.dob = fullFormData.dob;

        dispatch(postCriminalRecordFormBegin());

        try {
            if (source === 'ccap') {
                const removableConfirmedRecords = getConfirmedRemovableCCAPRecords(getState());
                await postCCAPCriminalRecordForm(userFormData, userId, removableConfirmedRecords, dispatch);
            } else if (source === 'doj') {
                const removableConfirmedRecords = getConfirmedRemovableDOJRecords(getState());
                await postDOJCriminalRecordForm(userFormData, userId, removableConfirmedRecords, dispatch);
            } else {
                throw Error(`Source not CCAP or DOJ but instead: ${source}`);
            }

            // create/update download link
            dispatch(postCriminalRecordFormDownload(source));
            onSuccess();
        } catch (error) {
            dispatch(postCriminalRecordFormError(error));
            onError();
        }
    }
);

const sendCriminalRecordFormBegin = () => ({
    type: CriminalRecordTypes.SEND_CRIMRECORD_FORM_BEGIN,
});
const sendCriminalRecordFormSuccess = () => ({
    type: CriminalRecordTypes.SEND_CRIMRECORD_FORM_SUCCESS,
});
const sendCriminalRecordFormError = errorMsg => ({
    type: CriminalRecordTypes.SEND_CRIMRECORD_FORM_ERROR,
    payload: { errorMsg },
});
export const sendCriminalRecordForm = (source, onSuccess, onError) => (
    async (dispatch, getState) => {
        const email = getEmailToSendForms(getState());
        const language = getLanguage(getState());
        const ccapFilledForms = getFilledCCAPFormPdfs(getState());
        const dojFilledForms = getFilledDOJFormPdfs(getState());
        const filledForms = source === 'ccap' ? ccapFilledForms : dojFilledForms;

        dispatch(sendCriminalRecordFormBegin());

        try {
            const payload = {
                ids: filledForms.map(f => f.id),
                email,
                language,
            };
            await WebClient.post('/forms/filled/send', payload);
            dispatch(sendCriminalRecordFormSuccess());
            onSuccess();
        } catch (error) {
            dispatch(sendCriminalRecordFormError(error));
            onError();
        }
    }
);
