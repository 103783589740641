import {
    isDate,
    parse,
    parseISO,
    format,
} from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

const locales = {
    en: enLocale,
    es: esLocale,
};

export const getDate = (date) => {
    if (isDate(date)) return date;

    const [nonTimezoneDate] = date.split('Z');
    return new Date(nonTimezoneDate);
};

export const getDateTime = (date, time = null) => {
    if (date && time) {
        const timeFormat = time.match(/[AaPp][Mm]$/) ? 'h:mma' : 'HH:mm:ss';
        return parse(time, timeFormat, getDate(date));
    }

    if (isDate(date)) return date;

    return parseISO(date);
};

export const getFormattedDate = (date, dateFormat, language = 'en') => (
    date ? format(getDate(date), dateFormat, { locale: locales[language] }) : ''
);
