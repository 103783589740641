import KeyMirror from 'keymirror';

export default KeyMirror({
    SET_COURTS: true,
    SET_DEBT_COLLECTION_BASIC_INFO: true,
    SET_DEBT_COLLECTION_CONFIRM: true,
    SET_DEBT_COLLECTION_ESIGNATURE: true,
    SET_DEBT_COLLECTION_SEND_TO_EMAIL: true,
    SET_HARASSMENT_INFO: true,
    POST_DEBT_COLLECTION_FORM_BEGIN: true,
    POST_DEBT_COLLECTION_FORM_SUCCESS: true,
    POST_DEBT_COLLECTION_FORM_ERROR: true,
    SEND_DEBT_COLLECTION_FORM_BEGIN: true,
    SEND_DEBT_COLLECTION_FORM_SUCCESS: true,
    SEND_DEBT_COLLECTION_FORM_ERROR: true,
    POST_DEBT_COLLECTION_FORM_DOWNLOAD_BEGIN: true,
    POST_DEBT_COLLECTION_FORM_DOWNLOAD_SUCCESS: true,
    POST_DEBT_COLLECTION_FORM_DOWNLOAD_ERROR: true,
    MAIL_DEBT_COLLECTION_FORM_BEGIN: true,
    MAIL_DEBT_COLLECTION_FORM_SUCCESS: true,
    MAIL_DEBT_COLLECTION_FORM_ERROR: true,
});
