const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    SET_USER_ACCOUNT_INFO: true,
    SET_USER_BASIC_INFO: true,
    SET_USER_IDENTIFICATION: true,
    CREATE_USER_ACCOUNT_BEGIN: true,
    CREATE_USER_ACCOUNT_SUCCESS: true,
    CREATE_USER_ACCOUNT_ERROR: true,
    UPDATE_USER_ACCOUNT_BEGIN: true,
    UPDATE_USER_ACCOUNT_SUCCESS: true,
    UPDATE_USER_ACCOUNT_ERROR: true,
    DELETE_USER_ACCOUNT_BEGIN: true,
    DELETE_USER_ACCOUNT_SUCCESS: true,
    DELETE_USER_ACCOUNT_ERROR: true,
    SEARCH_RECORDS_BEGIN: true,
    SEARCH_RECORDS_SUCCESS: true,
    SEARCH_RECORDS_ERROR: true,
    GET_FILLED_FORMS_BEGIN: true,
    GET_FILLED_FORMS_SUCCESS: true,
    GET_FILLED_FORMS_ERROR: true,
});
