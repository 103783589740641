import KeyMirror from 'keymirror';

import CriminalRecordTypes from '../action-types/crimrecord';
import UserTypes from '../action-types/user';
import SessionTypes from '../action-types/session';

export const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        ccap: {
            emailedAt: null,
            records: {},
        },
        doj: {
            emailedAt: null,
            records: {},
            pollingTimer: null,
            requestStatus: null,
            errorMsg: null,
        },
        form: {
            data: {
                address: null,
                apt: null,
                city: null,
                state: null,
                zip: null,
                phone: null,
                email: null,
                eSignature: null,
            },
            filledFormPdfs: {},
            requestStatus: null,
            errorMsg: null,
            PDFDownloadLink: '',
            responseCourtInfo: null,
        },
        send: {
            email: null,
            requestStatus: null,
            errorMsg: null,
        },
    }),
    dojResponseCourtInfo: {
        title: 'Wisconsin Department of Justice',
        address1: 'P.O. Box 7857',
        city: 'Madison',
        state: 'WI',
        zip: '53707-7857',
        phone: '(608) 266-1221',
    },
};

const parseCriminalRecords = (criminalRecords) => {
    const parsedRecords = {};

    criminalRecords.forEach((criminalRecord, i) => {
        const { caseNumber } = criminalRecord;
        parsedRecords[caseNumber] = {
            ...criminalRecord,
            // default to false until the user has had a chance to confirm or deny
            isConfirmed: false,
            index: i,
        };

        // some criminal records may not have an array of charges but we can
        // use the overall class and type of the criminal record instead
        if (!criminalRecord.charges || !criminalRecord.charges.length) {
            parsedRecords[caseNumber].charges = [{
                description: criminalRecord.class,
                severity: criminalRecord.type,
            }];
        }
    });

    return parsedRecords;
};

const CriminalRecordReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case CriminalRecordTypes.EMAIL_CRIMRECORD_FORM_SUCCESS: {
        if (payload.type === 'worcs') {
            return {
                ...state,
                doj: {
                    ...state.doj,
                    emailedAt: payload.emailedAt,
                    emailedTo: payload.emailedTo,
                },
            };
        }
        if (payload.type === 'criminal') {
            return {
                ...state,
                ccap: {
                    ...state.ccap,
                    emailedAt: payload.emailedAt,
                    emailedTo: payload.emailedTo,
                },
            };
        }
        return { ...state };
    }
    case CriminalRecordTypes.SEARCH_DOJ_RECORDS_BEGIN:
        return {
            ...state,
            doj: {
                ...state.doj,
                requestStatus: REQUEST_STATUS.ACTIVE,
                pollingTimer: null,
                errorMsg: null,
            },
        };
    case CriminalRecordTypes.SEARCH_DOJ_RECORDS_SUCCESS:
        return {
            ...state,
            doj: {
                ...state.doj,
                records: parseCriminalRecords(payload.records),
                requestStatus: REQUEST_STATUS.SUCCESS,
                errorMsg: null,
            },
        };
    case CriminalRecordTypes.SEARCH_DOJ_RECORDS_SET_TIMER:
        return {
            ...state,
            doj: {
                ...state.doj,
                pollingTimer: payload.timer,
            },
        };
    case CriminalRecordTypes.SEARCH_DOJ_RECORDS_ERROR:
        return {
            ...state,
            doj: {
                ...state.doj,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case UserTypes.SEARCH_RECORDS_SUCCESS: {
        if (payload.ccap) {
            return {
                ...state,
                ccap: {
                    ...state.ccap,
                    records: parseCriminalRecords(payload.ccap.criminal),
                },
            };
        }
        return state;
    }
    case CriminalRecordTypes.SET_CRIMRECORD_BASIC_INFO:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    ...payload.formData,
                },
            },
        };
    case CriminalRecordTypes.SET_CRIMRECORD_CONFIRM:
        return {
            ...state,
            [payload.source]: {
                ...state[payload.source],
                records: {
                    ...state[payload.source].records,
                    [payload.caseNumber]: {
                        ...state[payload.source].records[payload.caseNumber],
                        isConfirmed: payload.isConfirmed,
                    },
                },
            },
        };
    case CriminalRecordTypes.SET_CRIMRECORD_ESIGNATURE:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    eSignature: payload.eSignature,
                },
            },
        };
    case CriminalRecordTypes.SET_CRIMRECORD_SEND_TO_EMAIL:
        return {
            ...state,
            send: {
                ...state.send,
                email: payload.email,
            },
        };
    case CriminalRecordTypes.POST_CRIMRECORD_FORM_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case CriminalRecordTypes.POST_CCAP_CRIMRECORD_FORM_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
                filledFormPdfs: {
                    ...state.form.filledFormPdfs,
                    ccap: payload.filledFormPdfs,
                },
            },
        };
    case CriminalRecordTypes.POST_DOJ_CRIMRECORD_FORM_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
                filledFormPdfs: {
                    ...state.form.filledFormPdfs,
                    doj: payload.filledFormPdfs,
                },
            },
        };
    case CriminalRecordTypes.POST_CRIMRECORD_FORM_ERROR:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case CriminalRecordTypes.POST_CCAP_CRIMRECORD_FORM_DOWNLOAD_SUCCESS: {
        const key = Object.keys(payload.responseCourtInfo)[0];
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: payload.PDFDownloadLink,
                responseCourtInfo: {
                    title: 'Clerk of Courts',
                    ...payload.responseCourtInfo[key],
                },
            },
        };
    }
    case CriminalRecordTypes.POST_DOJ_CRIMRECORD_FORM_DOWNLOAD_SUCCESS: {
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: payload.PDFDownloadLink,
                responseCourtInfo: internals.dojResponseCourtInfo,
            },
        };
    }
    case CriminalRecordTypes.POST_CRIMRECORD_FORM_DOWNLOAD_ERROR:
    case CriminalRecordTypes.POST_CRIMRECORD_FORM_DOWNLOAD_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: '',
                responseCourtInfo: null,
            },
        };
    case CriminalRecordTypes.SEND_CRIMRECORD_FORM_BEGIN:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case CriminalRecordTypes.SEND_CRIMRECORD_FORM_SUCCESS:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.SUCCESS,
            },
        };
    case CriminalRecordTypes.SEND_CRIMRECORD_FORM_ERROR:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case SessionTypes.CLEAR_SESSION:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default CriminalRecordReducer;
