import { createSelector } from 'reselect';

const getSessionState = state => state.session;
const getUserState = state => state.user;

export const getReCaptchaKeyAccepted = createSelector(
    getSessionState,
    state => state.reCaptchaKeyAccepted,
);

export const getResultsGenerated = createSelector(
    getUserState,
    state => state.form && state.form.requestStatus === 'SUCCESS',
);

export const getSessionTimer = createSelector(
    getSessionState,
    state => state.timer,
);

export const getPrivacyAccepted = createSelector(
    getSessionState,
    state => state.privacyAccepted,
);
