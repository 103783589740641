import WebClient from '../utils/web-client';
import OrderTypes from '../action-types/supportorders';
import { getFormattedDate } from '../utils/date-utils';
import { getUserFormData, getUserId } from '../selectors/user';
import { getCSOCFormData, getFilledCSOCFormPdfs } from '../selectors/childsupport';

const CSOC_MODIFY_FORM_ID = 4;
const FEE_WAIVER_FORM_ID = 5;

const setSupportOrders = payload => ({
    type: OrderTypes.SET_USER_SUPPORT_ORDERS,
    payload,
});

const getSupportOrders = () => async (dispatch) => {
    try {
        const { data } = await WebClient.get('/users/csoc');
        return dispatch(setSupportOrders({ orders: data.orders || [] }));
    } catch (err) {
        return [];
    }
};

export const setCSOCESignature = eSignature => ({
    type: OrderTypes.SET_CSOC_ESIGNATURE,
    payload: { eSignature },
});

export const setCSOCBasicInfo = formData => ({
    type: OrderTypes.SET_CSOC_BASIC_INFO,
    payload: { formData },
});

const postCSOCModifyRecordFormSuccess = filledFormPdfs => ({
    type: OrderTypes.POST_CSOC_FORM_SUCCESS,
    payload: { filledFormPdfs },
});

const postCSOCFormBegin = () => ({
    type: OrderTypes.POST_CSOC_FORM_BEGIN,
});

const postCSOCFormError = errorMsg => ({
    type: OrderTypes.POST_CSOC_FORM_ERROR,
    payload: { errorMsg },
});

const postCSOCFormDownloadBegin = () => ({
    type: OrderTypes.POST_CSOC_FORM_DOWNLOAD_BEGIN,
});

const postCSOCFormDownloadSuccess = (PDFDownloadLink, responseCourtInfo, responseSupportOfficeInfo) => ({
    type: OrderTypes.POST_CSOC_FORM_DOWNLOAD_SUCCESS,
    payload: { PDFDownloadLink, responseCourtInfo, responseSupportOfficeInfo },
});

const postCSOCFormDownloadError = errorMsg => ({
    type: OrderTypes.POST_CSOC_FORM_DOWNLOAD_ERROR,
    payload: { errorMsg },
});

const postCSOCFormDownload = () => (
    async (dispatch, getState) => {
        const filledForms = getFilledCSOCFormPdfs(getState());

        const payload = {
            ids: filledForms.map(f => f.id),
        };

        dispatch(postCSOCFormDownloadBegin());

        try {
            const response = await WebClient.post('/forms/download', payload);

            dispatch(postCSOCFormDownloadSuccess(response.data.url, response.data.courts, response.data.supportOffices));
        } catch (error) {
            dispatch(postCSOCFormDownloadError(error));
        }
    }
);

const postCSOCModifyForm = async (userFormData, ccapData, kcNumber, userId, caseData, dispatch) => {
    const petitionerName = `${userFormData.firstName} ${userFormData.lastName}`.toUpperCase();
    const kidsCase = caseData.payeeKIDSCases.find(({ payeeKIDSCaseNumber }) => payeeKIDSCaseNumber === kcNumber);

    const respondentName = `${kidsCase.cpFirstName} ${kidsCase.cpLastName}`;
    const arrearsOrders = kidsCase.courtOrders.filter(({ debtType }) => debtType.includes('Arrears'));
    const supportOrders = kidsCase.courtOrders.filter(({ debtType }) => debtType.includes('Current Support'));

    const arrearsAmount = arrearsOrders.reduce((acc, val) => acc + (val.debtAmount ? parseFloat(val.debtAmount) : 0), 0);
    const supportAmount = supportOrders.reduce((acc, val) => acc + (val.debtAmount ? parseFloat(val.debtAmount) : 0), 0);
    const arrearsOrder = kidsCase.courtOrders.find(({ debtType }) => debtType.includes('Arrears'));
    const supportOrder = kidsCase.courtOrders.find(({ debtType }) => debtType.includes('Current Support'));
    const isSupport = !!supportOrder;
    const isArrears = !!arrearsOrder;

    let circumstancesEmploymentParty = null;
    let circumstancesIncomeParty = null;
    let county = null;

    if (userFormData.county) {
        const { county: userFormDataCounty } = userFormData;
        county = userFormDataCounty;
    } else if (ccapData.county) {
        const { county: ccapDataCounty } = ccapData;
        county = ccapDataCounty;
    }

    if (userFormData.employment && !userFormData.employmentOther) {
        circumstancesEmploymentParty = petitionerName;
    } else if (userFormData.employmentOther && !userFormData.employment) {
        circumstancesEmploymentParty = respondentName;
    }

    if (userFormData.income && !userFormData.incomeOther) {
        circumstancesIncomeParty = petitionerName;
    } else if (userFormData.incomeOther && !userFormData.income) {
        circumstancesIncomeParty = respondentName;
    }

    let courtOrderDate = null;

    if (isSupport && supportOrder) {
        courtOrderDate = supportOrder.effectiveDate;
    } else if (isArrears && arrearsOrder) {
        courtOrderDate = arrearsOrder.effectiveDate;
    }

    let childSupportNewPayer = null;

    if (isSupport) {
        childSupportNewPayer = userFormData.circumstancesChildLivesWithMe ? respondentName : petitionerName;
    }

    let childSupportNewRecipient = null;

    if (isSupport) {
        childSupportNewRecipient = userFormData.circumstancesChildLivesWithMe ? petitionerName : respondentName;
    }

    const basePayload = {
        0: {
            petitioner: petitionerName,
            respondent: respondentName,
            county,
            marriage: caseData.courtCaseNumber.includes('FA'),
            paternity: caseData.courtCaseNumber.includes('PA'),
            petitionerName,
            petitionerAddress: `${userFormData.petitionerAddress}`.toUpperCase(),
            petitionerCity: `${userFormData.petitionerCity}`.toUpperCase(),
            petitionerState: `${userFormData.petitionerState}`.toUpperCase(),
            petitionerZip: userFormData.petitionerZip,
            petitionerPhone: userFormData.phone,
            childSupport: isSupport,
            arrears: isArrears,
            caseNumber: caseData.courtCaseNumber,
            respondentName,
            respondentAddress: userFormData.respondentAddress ? `${userFormData.respondentAddress}`.toUpperCase() : null,
            respondentCity: userFormData.respondentCity ? `${userFormData.respondentCity}`.toUpperCase() : null,
            respondentState: userFormData.respondentState ? `${userFormData.respondentState}`.toUpperCase() : null,
            respondentZip: userFormData.respondentZip,
            respondentPhone: userFormData.respondentPhone,
            csaIsParty: true,
            to: respondentName,
        },
        1: {
            petitioner2: petitionerName,
            respondent2: respondentName,
            childSupport2: isSupport,
            currentSupportAmount: isSupport ? supportAmount.toFixed(2) : null,
            currentSupportPeriod: isSupport ? supportOrder.periodDescription : null,
            childSupportHIDeviation: null,
            childSupportHIDeviationIncluded: userFormData.childSupportHIDeviation,
            childSupportHIDeviationAmount: userFormData.childSupportHIDeviationAmount,
            childSupportHIDeviationUpward: userFormData.childSupportHIDeviationUpward,
            childSupportHIDeviationDownward: userFormData.childSupportHIDeviationDownward,
            childSupportNewDate: isSupport ? getFormattedDate(new Date(), 'MM/dd/yyyy') : null,
            childSupportNewPayer,
            childSupportNewRecipient,
            childSupportCourtReason: isSupport,
            arrears2: isArrears,
            arrearsCourtReason: isArrears,
            currentArrearsAmount: isArrears ? arrearsAmount.toFixed(2) : null,
            currentArrearsPeriod: isArrears ? arrearsOrder.periodDescription : null,
            arrearsCourtDate: isArrears ? getFormattedDate(new Date(), 'MMMM dd') : null,
            arrearsCourtYear: isArrears ? getFormattedDate(new Date(), 'yy') : null,
        },
        2: {
            petitioner3: petitionerName,
            respondent3: respondentName,
            courtOrderDate,
            circumstancesChildLivesWithMe: userFormData.circumstancesChildLivesWithMe,
            circumstancesChildNotEligible: userFormData.circumstancesChildNotEligible,
            circumstancesMoving: userFormData.circumstancesMoving,
            circumstancesNotLivingTogether: userFormData.circumstancesNotLivingTogether,
            circumstancesPlacementSchedule: userFormData.circumstancesPlacementSchedule,
            circumstancesEmploymentChanged: userFormData.employment || userFormData.employmentOther ? 'X' : null,
            circumstancesEmploymentParty,
            circumstancesBothPartyEmployment: userFormData.employment && userFormData.employmentOther,
            circumstancesIncomeChanged: userFormData.income || userFormData.incomeOther ? 'X' : null,
            circumstancesIncomeParty,
            circumstancesIncomeBothParty: userFormData.income && userFormData.incomeOther,
            circumstancesHealthInsurance: userFormData.circumstancesHealthInsurance,
            circumstancesOther: userFormData.circumstancesOther,
            circumstancesOtherExplained: userFormData.circumstancesOtherExplained,
            substantialChangeFacts: userFormData.substantialChangeFacts,
            printedName: petitionerName,
            signature: userFormData.eSignature,
            signatureAddress: `${userFormData.petitionerAddress}, ${userFormData.petitionerCity}, ${userFormData.petitionerState} ${userFormData.petitionerZip}`.toUpperCase(),
            signatureEmail: userFormData.email,
            signaturePhone: userFormData.phone,
            signatureDate: getFormattedDate(new Date(), 'MM/dd/yyyy'),
        },
        userId,
    };

    const payload = {
        formData: {
            ...basePayload,
            county,
            caseNumber: `${caseData.courtCaseNumber}-${kcNumber}`,
            dob: userFormData.dob,
            date: getFormattedDate(new Date(), 'MM/dd/yyyy'),
        },
    };

    const response = await WebClient.post(`/forms/csoc/${CSOC_MODIFY_FORM_ID}/fill`, payload);

    const filledForms = [{
        ...response.data,
        createdAt: new Date(),
        type: 'csoc',
    }];

    if (userFormData.benefits) {
        const waiverPayload = {
            formData: {
                0: {
                    county,
                    petitioner: petitionerName,
                    respondent: respondentName,
                    caseNumber: caseData.courtCaseNumber,
                    unableToPay: true,
                    ssi: userFormData.ssi,
                    medicaid: userFormData.medicaid,
                    foodStamps: userFormData.foodStamps,
                    financialSituationNotChanged: true,
                },
                1: {
                    respondent2: petitionerName,
                    dateOfBirth: userFormData.dob,
                    address: `${userFormData.petitionerAddress}, ${userFormData.petitionerCity}, ${userFormData.petitionerState} ${userFormData.petitionerZip}`.toUpperCase(),
                    email: userFormData.email,
                    phone: userFormData.phone,
                },
                userId,
                county,
                address1: userFormData.petitionerAddress,
                city: userFormData.petitionerCity,
                state: userFormData.petitionerState,
                zip: userFormData.petitionerZip,
                caseNumber: `${caseData.courtCaseNumber}-${kcNumber} Fee Waiver`,
            },
        };

        try {
            const waiverResponse = await WebClient.post(`/forms/waiver/${FEE_WAIVER_FORM_ID}/fill`, waiverPayload);

            filledForms.push({
                ...waiverResponse.data,
                createdAt: new Date(),
                type: 'csoc',
            });
        } catch (err) {
            /* eslint-disable no-console */
            console.log(err);
            /* eslint-enable no-console */
        }
    }

    dispatch(postCSOCModifyRecordFormSuccess(filledForms));
};

export const postCSOCForm = (caseData, ccapData, kcNumber, onSuccess, onError) => async (dispatch, getState) => {
    const userFormData = getCSOCFormData(getState());
    const userId = getUserId(getState());

    const fullFormData = getUserFormData(getState());
    userFormData.dob = fullFormData.dob;

    dispatch(postCSOCFormBegin());

    try {
        await postCSOCModifyForm(userFormData, ccapData, kcNumber, userId, caseData, dispatch);
        dispatch(postCSOCFormDownload());
        onSuccess();
    } catch (error) {
        dispatch(postCSOCFormError(error));
        onError();
    }
};

export default getSupportOrders;
