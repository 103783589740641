import KeyMirror from 'keymirror';

import UserTypes from '../action-types/user';
import EvictionTypes from '../action-types/eviction';
import CriminalRecordTypes from '../action-types/crimrecord';
import SessionTypes from '../action-types/session';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        form: {
            data: {
                phone: null,
                firstName: null,
                lastName: null,
                dob: null,
                income: null,
                gender: null,
                race: null,
                socialSecurity: null,
                driversLicense: null,
                v2Token: null,
                v3Token: null,
            },
            requestStatus: null,
            errorMsg: null,
        },
        previouslyFilledForms: {
            data: [],
            requestStatus: null,
            errorMsg: null,
        },
    }),
};

const UserReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SET_USER_ACCOUNT_INFO:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: null, // reset value before entering api workflow
                data: {
                    ...state.form.data,
                    ...payload.accountInfo,
                },
            },
        };
    case UserTypes.SET_USER_BASIC_INFO:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: null, // reset value before entering api workflow
                data: {
                    ...state.form.data,
                    ...payload.formData,
                },
            },
        };
    case UserTypes.SET_USER_IDENTIFICATION:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    ...payload.idValues,
                },
            },
        };
    case UserTypes.CREATE_USER_ACCOUNT_BEGIN:
    case UserTypes.UPDATE_USER_ACCOUNT_BEGIN:
    case UserTypes.SEARCH_RECORDS_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case UserTypes.CREATE_USER_ACCOUNT_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
            },
        };
    case UserTypes.UPDATE_USER_ACCOUNT_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
                data: {
                    ...state.form.data,
                    ...payload.userData,
                },
            },
        };
    case UserTypes.SEARCH_RECORDS_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
                data: { // manually pull reCaptcha tokens out of dataset
                    ...state.form.data,
                    v2Token: null,
                    v3Token: null,
                },
            },
        };
    case UserTypes.CREATE_USER_ACCOUNT_ERROR:
    case UserTypes.UPDATE_USER_ACCOUNT_ERROR:
    case UserTypes.SEARCH_RECORDS_ERROR:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case UserTypes.GET_FILLED_FORMS_BEGIN:
        return {
            ...state,
            previouslyFilledForms: {
                ...state.previouslyFilledForms,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case UserTypes.GET_FILLED_FORMS_SUCCESS:
        return {
            ...state,
            previouslyFilledForms: {
                ...state.previouslyFilledForms,
                requestStatus: REQUEST_STATUS.SUCCESS,
                data: payload.filledForms,
            },
        };
    case EvictionTypes.POST_EVICTION_FORM_SUCCESS:
    case CriminalRecordTypes.POST_CCAP_CRIMRECORD_FORM_SUCCESS:
    case CriminalRecordTypes.POST_DOJ_CRIMRECORD_FORM_SUCCESS:
        const formCaseNumbers = payload.filledFormPdfs.map(form => form.caseNumber); // eslint-disable-line no-case-declarations

        return {
            ...state,
            previouslyFilledForms: {
                ...state.previouslyFilledForms,
                data: [
                    // we post to fill forms once to preview the forms to the user
                    // and then a second time once they've confirmed and added their signature
                    // to prevent adding duplicate forms for the same case, remove any that we've posted previously
                    ...state.previouslyFilledForms.data
                        .filter(form => !formCaseNumbers.includes(form.caseNumber)),
                    ...payload.filledFormPdfs,
                ],
            },
        };
    case EvictionTypes.MAIL_EVICTION_FORM_SUCCESS:
    case CriminalRecordTypes.MAIL_CRIMRECORD_FORM_SUCCESS:
        return {
            ...state,
            previouslyFilledForms: {
                ...state.previouslyFilledForms,
                data: state.previouslyFilledForms.data.map((filledForm) => {
                    if (payload.formIds.includes(filledForm.id)) {
                        return {
                            ...filledForm,
                            mailedAt: payload.mailedAt,
                        };
                    }
                    return filledForm;
                }),
            },
        };
    case UserTypes.GET_FILLED_FORMS_ERROR:
        return {
            ...state,
            previouslyFilledForms: {
                ...state.previouslyFilledForms,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case SessionTypes.CLEAR_SESSION:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default UserReducer;
