import UserTypes from '../action-types/user';
import SessionTypes from '../action-types/session';

const internals = {
    initial: () => ({
        records: {},
        results: {},
        status: {},
    }),
};

const DOTReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SEARCH_RECORDS_SUCCESS: {
        if (payload.dot) {
            const { records, status, ...rest } = payload.dot;
            return {
                ...state,
                records,
                results: {
                    status,
                    ...rest,
                },
            };
        }
        return internals.initial();
    }

    case SessionTypes.CLEAR_SESSION:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default DOTReducer;
