import { CheckToken } from '../actions/auth';
import { searchRecords, getFilledForms } from '../actions/user';
import { checkDojRecordsSearch } from '../actions/crimrecord';
import getSupportOrders from '../actions/supportorders';
import { getCourts } from '../actions/debt-collection';

export default (store) => {
    store.dispatch(CheckToken());

    // load base data needed for the application
    store.dispatch(searchRecords());
    store.dispatch(checkDojRecordsSearch());
    store.dispatch(getFilledForms());
    store.dispatch(getSupportOrders());
    store.dispatch(getCourts());
};
