import history from '../wiring/history';
import WebClient from '../utils/web-client';
import AuthTypes from '../action-types/auth';
import { getLoginPhone, getUserEmail } from '../selectors/user';

import { setUserAccountInfo } from './user';
import { clearSession } from './session';

const preparePhoneNumber = phone => phone.replace(/[()-\s]*/g, '');

export const ClearErrors = () => ({
    type: AuthTypes.INITIALIZE_APP,
});

const LoginBegin = payload => ({
    type: AuthTypes.LOGIN_BEGIN,
    payload,
});
const LoginSuccess = (token, role) => ({
    type: AuthTypes.LOGIN_SUCCESS,
    payload: {
        token,
        role,
    },
});
const LoginError = errorMsg => ({
    type: AuthTypes.LOGIN_ERROR,
    payload: { errorMsg },
});

export const SetupNewUserBegin = () => ({
    type: AuthTypes.SETUP_NEW_USER_BEGIN,
});
export const SetupNewUserSuccess = () => ({
    type: AuthTypes.SETUP_NEW_USER_SUCCESS,
});

export const SendVerificationCode = (inputType, phone, email, v2Token, v3Token, onSuccess, onError, src = 'login') => (
    async (dispatch) => {
        const payload = {};
        if (inputType === 'phone') {
            const loginPhone = preparePhoneNumber(phone);
            payload.phone = loginPhone;
        } else { // assumes inputType === email
            payload.email = email;
        }
        if (v3Token) payload.v3Token = v3Token;
        if (v2Token) payload.v2Token = v2Token;

        dispatch(setUserAccountInfo(payload));
        dispatch(LoginBegin(payload));

        try {
            await WebClient.post('/send-code', payload);

            if (onSuccess) onSuccess();
        } catch (error) {
            // 404 indicates this is a new user that has not created an account yet
            if (error.response && (error.response.status === 404)) {
                dispatch(SetupNewUserBegin());
                history.push(`/login/intake?src=${src}`);
                return;
            }
            dispatch(LoginError(error));
            if (onError) onError(error.response.data.errors.error[0]);
        }
    }
);

export const ResendVerificationCode = (onSuccess, onError) => (
    async (dispatch, getState) => {
        const payload = {};
        const loginPhone = getLoginPhone(getState());
        const loginEmail = getUserEmail(getState());

        if (loginPhone) payload.phone = loginPhone;
        else payload.email = loginEmail;

        dispatch(LoginBegin(payload));

        try {
            await WebClient.post('/send-code', payload);

            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(LoginError(error));
            if (onError) onError();
        }
    }
);

export const Login = (verificationCode, onError) => (
    async (dispatch, getState) => {
        const payload = {};
        const loginPhone = getLoginPhone(getState());
        const loginEmail = getUserEmail(getState());

        if (loginPhone) payload.phone = loginPhone;
        else payload.email = loginEmail;

        payload.smsCode = verificationCode;

        try {
            const { data } = await WebClient.post('/sms-login', payload, { responseType: 'text' });

            // update auth token
            WebClient.updateAuth(data.token);

            dispatch(setUserAccountInfo(data.user));
            dispatch(LoginSuccess(data.token, data.user.role));
        } catch (error) {
            let errorMsg = 'Error';
            if (error.response && (error.response.status === 401)) {
                errorMsg = 'Invalid verification code';
                onError('invalidCode');
            } else {
                errorMsg = 'Error: Unknown';
                onError('genericError');
            }
            dispatch(LoginError(errorMsg));
        }
    }
);

export const Logout = (redirect = null) => (dispatch) => {
    history.push(redirect || '/');
    dispatch(clearSession());

    return {
        type: AuthTypes.LOGOUT,
    };
};

const CheckTokenBegin = () => ({
    type: AuthTypes.CHECK_TOKEN_BEGIN,
});
const CheckTokenSuccess = () => ({
    type: AuthTypes.CHECK_TOKEN_SUCCESS,
});
const CheckTokenError = () => ({
    type: AuthTypes.CHECK_TOKEN_ERROR,
});

export const CheckToken = () => (dispatch) => {
    dispatch(CheckTokenBegin());
    const checkToken = WebClient.get('/users/authenticated');

    checkToken
        .then(() => dispatch(CheckTokenSuccess()))
        .catch(() => {
            dispatch(CheckTokenError());
            dispatch(Logout());
        });
};

const RequestResetBegin = () => ({
    type: AuthTypes.REQUEST_RESET_BEGIN,
});
const RequestResetSuccess = () => ({
    type: AuthTypes.REQUEST_RESET_SUCCESS,
});
const RequestResetError = errorMsg => ({
    type: AuthTypes.REQUEST_RESET_ERROR,
    payload: { errorMsg },
});

export const RequestReset = (phone, email, onSuccess, onError) => (
    async (dispatch) => {
        dispatch(RequestResetBegin());

        const payload = {
            phone: preparePhoneNumber(phone),
            email,
        };

        try {
            await WebClient.post('/users/request-reset', payload);
            dispatch(RequestResetSuccess());
            if (onSuccess) onSuccess();
        } catch (error) {
            let errorMsg = 'Error';

            if (error.response && (error.response.status === 404)) {
                errorMsg = 'User not found';
                onError('notFound');
            } else if (error.response && (error.response.status === 400)) {
                errorMsg = 'Invalid credentials';
                onError('noEmail');
            } else {
                errorMsg = 'Error: Unknown';
                onError('genericError');
            }

            dispatch(RequestResetError(errorMsg));
        }
    }
);

const ResetBegin = phone => ({
    type: AuthTypes.RESET_BEGIN,
    payload: { phone },
});
const ResetSuccess = (token, role) => ({
    type: AuthTypes.RESET_SUCCESS,
    payload: {
        token,
        role,
    },
});
const ResetError = errorMsg => ({
    type: AuthTypes.RESET_ERROR,
    payload: { errorMsg },
});

export const Reset = (phone, userId, resetToken, onError) => (
    async (dispatch) => {
        const newPhone = preparePhoneNumber(phone);
        dispatch(ResetBegin(newPhone));

        const payload = {
            newPhone,
            resetToken,
        };

        try {
            const { data } = await WebClient.post(`/users/${userId}/reset`, payload);

            // update auth token
            WebClient.updateAuth(data.token);

            dispatch(setUserAccountInfo(data.user));
            dispatch(ResetSuccess(data.token, data.user.role));
        } catch (error) {
            let errorMsg = 'Error';

            if (error.response && (error.response.status === 404)) {
                errorMsg = 'User not found';
                onError('notFound');
            } else {
                errorMsg = 'Error: Unknown';
                onError('genericError');
            }

            dispatch(ResetError(errorMsg));
        }
    }
);
