import GA4React from 'ga-4-react';
import * as Sentry from '@sentry/react';

import History from '../wiring/history';

let ga4React;
const GAUtils = {};
GAUtils.initGA = async () => {
    try {
        ga4React = new GA4React(process.env.REACT_APP_GA4);
        await ga4React.initialize();

        // path,location,title
        // title is what shows in the top view in the GA dash, and by default it's
        // always going to be the app name, which is worthless info.
        History.listen(location => ga4React.pageview(location.pathname, location.search, location.pathname));
    } catch (error) {
        Sentry.captureException(error);
    }
};


export default GAUtils;

const sendEvent = (category, action) => {
    try {
        ga4React.gtag('event', `${category}-${action}`);
    } catch (error) {
        Sentry.captureException(error);
    }
};

export const sendCustomEvent = (label, data) => {
    if (!label) {
        return;
    }
    try {
        ga4React.gtag('event', label, data || {});
    } catch (err) {
        console.log('event not sent');
    }
};

export const CompleteIntake = () => {
    sendEvent('intake', 'complete');
};

export const StartSecondaryFlow = (flow) => {
    sendEvent(flow, 'start');
};

export const CompleteSecondaryFlow = (flow) => {
    sendEvent(flow, 'complete');
};
