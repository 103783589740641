import WebClient from '../utils/web-client';
import history from '../wiring/history';
import SessionTypes from '../action-types/session';
import { getSessionTimer, getResultsGenerated } from '../selectors/session';
import { hasUserData } from '../selectors/user';

const verifyReCaptchaBegin = () => ({
    type: SessionTypes.VERIFY_RECAPTCHA_BEGIN,
});
const verifyReCaptchaSuccess = payload => ({
    type: SessionTypes.VERIFY_RECAPTCHA_SUCCESS,
    payload,
});
const verifyReCaptchaError = errorMsg => ({
    type: SessionTypes.VERIFY_RECAPTCHA_ERROR,
    payload: { errorMsg },
});

export const verifyReCaptcha = (token, onSuccess, onError) => (async (dispatch) => {
    dispatch(verifyReCaptchaBegin());

    try {
        const { data } = await WebClient.post('/users/search/recaptcha', { token });
        // display === true, means, show v2
        dispatch(verifyReCaptchaSuccess(!data.display));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(verifyReCaptchaError(error));
        if (onError) onError();
    }
});

export const setPrivacyAccepted = payload => ({
    type: SessionTypes.SET_ACCEPT_PRIVACY,
    payload,
});

const internals = {
    sessionLifetime: process.env.REACT_APP_SESSION_LIFETIME || (1000 * 60 * 30), // 30 mins (in milliseconds)
};

export const clearSession = () => ({
    type: SessionTypes.CLEAR_SESSION,
});

const setSessionTimer = timer => ({
    type: SessionTypes.SET_SESSION_TIMER,
    payload: { timer },
});

const clearSessionTimer = () => ({
    type: SessionTypes.CLEAR_SESSION_TIMER,
});

export const startOver = () => (
    (dispatch) => {
        dispatch(clearSession());
        history.push('/');
        // history.go(0);
    }
);

export const continueSession = () => (
    (dispatch, getState) => {
        const prevSessionTimer = getSessionTimer(getState());
        const hasResults = getResultsGenerated(getState());
        const userDataExists = hasUserData(getState());

        if (prevSessionTimer) {
            clearTimeout(prevSessionTimer);
            dispatch(clearSessionTimer());
        }

        // only bother creating a session timer if we have data in the store
        if (hasResults || userDataExists) {
            const expireSession = () => {
                history.push('/error/sessionexpired');
                dispatch(clearSession());
            };

            const sessionTimer = setTimeout(expireSession, internals.sessionLifetime);
            dispatch(setSessionTimer(sessionTimer));
        }
    }
);
