import { createSelector } from 'reselect';

export const getDebtCollectionState = state => state.debtCollection;

export const getFoundDebtCollections = createSelector(
    getDebtCollectionState,
    debtCollections => Object.values(debtCollections.records),
);

// since SC search could return more than debt collections, need confirm?
export const getConfirmedCollections = createSelector(
    getFoundDebtCollections,
    debtCollections => debtCollections.filter(record => record.isConfirmed),
);

export const getCountDebtCollections = createSelector(
    getFoundDebtCollections,
    foundRecords => foundRecords.length,
);

export const getCourtsInformation = createSelector(getDebtCollectionState, debtCollections => debtCollections.courts);

// TODO: add form related selectors when we know which forms we'll use

const getDebtCollectionFormState = createSelector(
    getDebtCollectionState,
    state => state.form,
);

export const getHarassmentInfo = createSelector(
    getDebtCollectionState,
    state => state.harassmentInfo,
);

export const getDebtCollectionFormData = createSelector(
    getDebtCollectionFormState,
    formState => formState.data,
);

export const getFilledFormPdfs = createSelector(
    getDebtCollectionFormState,
    formState => formState.filledFormPdfs,
);

export const getEmailToSendForms = createSelector(
    getDebtCollectionState,
    state => state.send.email,
);
