import KeyMirror from 'keymirror';

export default KeyMirror({
    SET_EVICTION_BASIC_INFO: true,
    SET_EVICTION_CONFIRM: true,
    SET_EVICTION_ESIGNATURE: true,
    SET_EVICTION_SEND_TO_EMAIL: true,
    POST_EVICTION_FORM_BEGIN: true,
    POST_EVICTION_FORM_SUCCESS: true,
    POST_EVICTION_FORM_ERROR: true,
    SEND_EVICTION_FORM_BEGIN: true,
    SEND_EVICTION_FORM_SUCCESS: true,
    SEND_EVICTION_FORM_ERROR: true,
    POST_EVICTION_FORM_DOWNLOAD_BEGIN: true,
    POST_EVICTION_FORM_DOWNLOAD_SUCCESS: true,
    POST_EVICTION_FORM_DOWNLOAD_ERROR: true,
    MAIL_EVICTION_FORM_BEGIN: true,
    MAIL_EVICTION_FORM_SUCCESS: true,
    MAIL_EVICTION_FORM_ERROR: true,
});
