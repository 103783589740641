import KeyMirror from 'keymirror';
import OrderTypes from '../action-types/supportorders';
import UserTypes from '../action-types/user';

export const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        ccap: [],
        orders: [],
        form: {
            data: {
                firstName: null,
                lastName: null,
                county: null,
                petitionerAddress: null,
                petitionerCity: null,
                petitionerState: null,
                petitionerZip: null,
                petitionerPhone: null,
                respondentAddress: null,
                respondentCity: null,
                respondentState: null,
                respondentZip: null,
                respondentPhone: null,
                childSupportHIDeviation: null,
                childSupportHIDeviationAmount: null,
                childSupportHIDeviationUpward: null,
                childSupportHIDeviationDownward: null,
                effectiveDate: null,
                circumstancesChildLivesWithMe: null,
                circumstancesChildNotEligible: null,
                circumstancesMoving: null,
                circumstancesNotLivingTogether: null,
                circumstancesPlacementSchedule: null,
                circumstancesEmploymentChanged: null,
                circumstancesEmploymentParty: null,
                circumstancesBothPartyEmployment: null,
                circumstancesIncomeChanged: null,
                circumstancesIncomeParty: null,
                circumstancesIncomeBothParty: null,
                circumstancesHealthInsurance: null,
                circumstancesOther: null,
                circumstancesOtherExplained: null,
                substantialChangeFacts: null,
                email: null,
                phone: null,
                dob: null,
                eSignature: null,
            },
            filledFormPdfs: {},
            requestStatus: null,
            errorMsg: null,
            PDFDownloadLink: '',
            responseCourtInfo: null,
        },
    }),
};

const SupportOrdersReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SEARCH_RECORDS_SUCCESS:
        return {
            ...state,
            ccap: payload.ccap.support,
        };
    case OrderTypes.SET_CSOC_BASIC_INFO:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    ...payload.formData,
                },
            },
        };
    case OrderTypes.SET_CSOC_ESIGNATURE:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    eSignature: payload.eSignature,
                },
            },
        };
    case OrderTypes.POST_CSOC_FORM_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: '',
                responseCourtInfo: null,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case OrderTypes.SET_USER_SUPPORT_ORDERS:
        return {
            ...state,
            orders: payload.orders || [],
        };
    case OrderTypes.POST_CSOC_FORM_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
                filledFormPdfs: {
                    ...state.form.filledFormPdfs,
                    csoc: payload.filledFormPdfs,
                },
            },
        };
    case OrderTypes.POST_CSOC_FORM_ERROR:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case OrderTypes.POST_CSOC_FORM_DOWNLOAD_SUCCESS: {
        const key = Object.keys(payload.responseCourtInfo)[0];
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: payload.PDFDownloadLink,
                responseCourtInfo: {
                    title: 'Clerk of Courts',
                    ...payload.responseCourtInfo[key],
                },
                responseSupportOfficeInfo: {
                    title: 'Support Office',
                    ...payload.responseSupportOfficeInfo[key],
                },
            },
        };
    }
    case OrderTypes.POST_CSOC_FORM_DOWNLOAD_ERROR:
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: '',
                responseCourtInfo: null,
            },
        };
    default:
        return state;
    }
};

export default SupportOrdersReducer;
