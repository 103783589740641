import KeyMirror from 'keymirror';

import EATATypes from '../../action-types/eata';
import SessionTypes from '../../action-types/session';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        answers: {},
        requestStatus: null,
        errorMsg: null,
    }),
};

const EATAReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case EATATypes.SAVE_ANSWERS:
        return {
            ...state,
            answers: {
                ...state.answers,
                [payload.stepNo]: payload.answers,
            },
        };
    case EATATypes.SEND_EATA_REQUEST_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
        };
    case EATATypes.SEND_EATA_REQUEST_SUCCESS:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case EATATypes.SEND_EATA_REQUEST_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case SessionTypes.CLEAR_SESSION:
        return internals.initial();
    default:
        // do nothing
    }
    return state;
};

export default EATAReducer;
