import KeyMirror from 'keymirror';

export default KeyMirror({
    SEARCH_DOJ_RECORDS_BEGIN: true,
    SEARCH_DOJ_RECORDS_SUCCESS: true,
    SEARCH_DOJ_RECORDS_SET_TIMER: true,
    SEARCH_DOJ_RECORDS_ERROR: true,
    SET_CRIMRECORD_BASIC_INFO: true,
    SET_CRIMRECORD_CONFIRM: true,
    SET_CRIMRECORD_ESIGNATURE: true,
    SET_CRIMRECORD_SEND_TO_EMAIL: true,
    POST_CRIMRECORD_FORM_BEGIN: true,
    POST_CCAP_CRIMRECORD_FORM_SUCCESS: true,
    POST_DOJ_CRIMRECORD_FORM_SUCCESS: true,
    POST_CRIMRECORD_FORM_ERROR: true,
    SEND_CRIMRECORD_FORM_BEGIN: true,
    SEND_CRIMRECORD_FORM_SUCCESS: true,
    SEND_CRIMRECORD_FORM_ERROR: true,
    POST_CRIMRECORD_FORM_DOWNLOAD_BEGIN: true,
    POST_CCAP_CRIMRECORD_FORM_DOWNLOAD_SUCCESS: true,
    POST_DOJ_CRIMRECORD_FORM_DOWNLOAD_SUCCESS: true,
    POST_CRIMRECORD_FORM_DOWNLOAD_ERROR: true,
    MAIL_CRIMRECORD_FORM_BEGIN: true,
    MAIL_CRIMRECORD_FORM_SUCCESS: true,
    MAIL_CRIMRECORD_FORM_ERROR: true,
    EMAIL_CRIMRECORD_FORM_BEGIN: true,
    EMAIL_CRIMRECORD_FORM_SUCCESS: true,
    EMAIL_CRIMRECORD_FORM_ERROR: true,
});
