import { createSelector } from 'reselect';

export const getUserState = state => state.user;

export const getUserFormData = createSelector(
    getUserState,
    user => (user.form ? user.form.data : {}),
);

export const getUserFirstName = createSelector(
    getUserFormData,
    user => user.firstName,
);

export const getUserFullName = createSelector(
    getUserFormData,
    user => `${user.firstName} ${user.lastName}`,
);

export const getUserId = createSelector(
    getUserFormData,
    user => user.id,
);

export const getUserDOB = createSelector(
    getUserFormData,
    user => user.dob,
);

export const getLoginPhone = createSelector(
    getUserFormData,
    user => user.phone,
);

export const getUserEmail = createSelector(
    getUserFormData,
    user => user.email,
);

export const getPreviouslyFilledForms = createSelector(
    getUserState,
    user => user.previouslyFilledForms.data,
);

export const getPreviouslyFilledCriminalForms = createSelector(
    getPreviouslyFilledForms,
    filledForms => filledForms.filter(form => form.type === 'criminal'),
);

export const getPreviouslyFilledEvictionForms = createSelector(
    getPreviouslyFilledForms,
    filledForms => filledForms.filter(form => form.type === 'eviction'),
);

export const getPreviouslyFilledDOJForms = createSelector(
    getPreviouslyFilledForms,
    filledForms => filledForms.filter(form => form.type === 'worcs'),
);

export const getPreviouslyFilledCSOCForms = createSelector(
    getPreviouslyFilledForms,
    filledForms => filledForms.filter(form => form.type === 'csoc'),
);

export const getRequestStatus = createSelector(
    getUserState,
    user => user.form.requestStatus,
);

export const hasUserData = createSelector(
    getUserFormData,
    user => !!user.firstName || !!user.lastName || !!user.dob,
);
