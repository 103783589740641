import KeyMirror from 'keymirror';

import DebtCollectionTypes from '../action-types/debt-collection';
import UserTypes from '../action-types/user';
import SessionTypes from '../action-types/session';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        records: {},
        courts: [],
        form: {
            data: {
                // confirm this is what is needed for collections form
                address: null,
                city: null,
                state: null,
                zip: null,
                phone: null,
                email: null,
                eSignature: null,
            },
            filledFormPdfs: [],
            requestStatus: null,
            errorMsg: null,
            PDFDownloadLink: '',
            // responseCourtInfo: null,
        },
        send: {
            email: null,
            requestStatus: null,
            errorMsg: null,
        },
        harassmentInfo: {},
    }),
};

const parseDebtCollections = (collections) => {
    if (!collections) {
        return {};
    }
    const parsedRecords = {};

    collections.forEach((collection, i) => {
        const { caseNumber } = collection;
        parsedRecords[caseNumber] = {
            ...collection,
            // default to false until the user has had a chance to confirm or deny
            isConfirmed: false,
            index: i,
        };
    });

    return parsedRecords;
};

const DebtCollectionReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case DebtCollectionTypes.SET_HARASSMENT_INFO:
        return {
            ...state,
            harassmentInfo: {
                ...state.harassmentInfo,
                ...payload.data,
            },
        };
    case DebtCollectionTypes.SET_COURTS:
        return {
            ...state,
            courts: payload.courts,
        };
    case DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                filledFormPdfs: payload.filledFormPdfs,
            },
        };
    case DebtCollectionTypes.SET_DEBT_COLLECTION_BASIC_INFO:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    ...payload.formData,
                },
            },
        };
    case UserTypes.SEARCH_RECORDS_SUCCESS:
        return {
            ...state,
            records: parseDebtCollections(payload.ccap.debtCollection),
        };
    case DebtCollectionTypes.SET_DEBT_COLLECTION_CONFIRM:
        return {
            ...state,
            records: {
                ...state.records,
                [payload.caseNumber]: {
                    ...state.records[payload.caseNumber],
                    isConfirmed: payload.isConfirmed,
                },
            },
        };
    case DebtCollectionTypes.SET_DEBT_COLLECTION_ESIGNATURE:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    eSignature: payload.eSignature,
                },
            },
        };
    case DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_ERROR:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case DebtCollectionTypes.SEND_DEBT_COLLECTION_FORM_BEGIN:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_DOWNLOAD_SUCCESS: {
        const key = Object.keys(payload.responseCourtInfo)[0];
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: payload.PDFDownloadLink,
                responseCourtInfo: {
                    title: 'Clerk of Courts',
                    ...payload.responseCourtInfo[key],
                },
            },
        };
    }
    case DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_DOWNLOAD_ERROR:
    case DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_DOWNLOAD_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: '',
                responseCourtInfo: null,
            },
        };
    case DebtCollectionTypes.SEND_DEBT_COLLECTION_FORM_SUCCESS:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.SUCCESS,
            },
        };
    case DebtCollectionTypes.SEND_DEBT_COLLECTION_FORM_ERROR:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case SessionTypes.CLEAR_SESSION:
        return internals.initial();
    default:
        // we do nothing here in other reducers.
    }
    return state;
};

export default DebtCollectionReducer;
