import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import 'bootstrap-css-only/css/bootstrap-grid.min.css';
// REDUX
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Router } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import ErrorFallback from './components/ErrorFallback';

import { ClearErrors } from './actions/auth';
import reducers from './reducers';

import browserHistory from './wiring/history';
import App from './routes';

import Initializers from './initializers';
import InitializerUser from './initializers/user';

import ScrollToTop from './utils/scroll-to-top';

import GAUtils from './utils/ga';

import 'sanitize.css/sanitize.css';
import './index.css';

const initialState = {};
const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
            }),
            new CaptureConsoleIntegration({
                // array of methods that should be captured
                // options include: ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['info', 'warn', 'error'],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
    });
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'user', 'crimrecord', 'eviction', 'dot'],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, initialState, composedEnhancers);
const persistedStore = persistStore(store);

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

history.listen(() => {
    ScrollToTop();
});

const onBeforeLift = () => {
    // Run initializers... anything that will need to use or subscribe to the store
    Initializers(store);

    // clear login/logout errors that may be in local storage
    store.dispatch(ClearErrors());

    if (store.getState().auth.isAuthenticated) {
        InitializerUser(store);
        // load any role specific content here, for example:
        // if (store.getState().auth.credentials.role === 'admin') {}
    }
};

GAUtils.initGA();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={null}
            persistor={persistedStore}
            onBeforeLift={onBeforeLift}
        >
            <Router history={history}>
                <Sentry.ErrorBoundary fallback={({ error, componentStack }) => <ErrorFallback error={error} componentStack={componentStack} />}>
                    <GoogleReCaptchaProvider
                        useEnterprise
                        reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
                    >
                        <App store={store} />
                    </GoogleReCaptchaProvider>
                </Sentry.ErrorBoundary>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
