import Colors from '../../styles/colors';

const styles = () => ({
    cancelButton: {
        backgroundColor: 'gray',
    },
    proceedButton: {
        backgroundColor: 'rgb(11,67,159)',
    },
    modalButton: {
        color: '#fff',
        marginLeft: '1rem',
        marginRight: '1rem',
        padding: '1rem 2rem',
    },
    modalContentWrapper: {
        paddingBottom: 40,
        paddingLeft: 60,
        paddingRight: 60,
    },
    modalPaper: {
        overflow: 'initial',
    },
    modalCloseButton: {
        position: 'absolute',
        right: -24,
        top: -24,
        '& svg': {
            fill: Colors.white,
            backgroundColor: Colors.black,
            borderRadius: 24,
        },
    },
});

export default styles;
