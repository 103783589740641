import { createSelector } from 'reselect';

export const getEvictionState = state => state.eviction;

const getEvictionFormState = createSelector(
    getEvictionState,
    state => state.form,
);

export const getEvictionFormData = createSelector(
    getEvictionFormState,
    formState => formState.data,
);

export const getFoundEvictions = createSelector(
    getEvictionState,
    evictions => Object.values(evictions.records),
);

export const getConfirmedEvictions = createSelector(
    getFoundEvictions,
    evictions => evictions.filter(record => record.isConfirmed),
);

export const getConfirmedRemovableEvictions = createSelector(
    getFoundEvictions,
    foundRecords => foundRecords.filter(record => record.isConfirmed && record.removable),
);

export const getCountEvictions = createSelector(
    getFoundEvictions,
    foundRecords => foundRecords.length,
);

export const getFilledFormPdfs = createSelector(
    getEvictionFormState,
    formState => formState.filledFormPdfs,
);
export const getPDFDownloadLink = createSelector(
    getEvictionFormState,
    formState => formState.PDFDownloadLink,
);
export const getResponseCourtInfo = createSelector(
    getEvictionFormState,
    formState => formState.responseCourtInfo,
);

export const getEmailToSendForms = createSelector(
    getEvictionState,
    state => state.send.email,
);
