import KeyMirror from 'keymirror';

import EvictionTypes from '../action-types/eviction';
import UserTypes from '../action-types/user';
import SessionTypes from '../action-types/session';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        records: {},
        form: {
            data: {
                address: null,
                city: null,
                state: null,
                zip: null,
                phone: null,
                email: null,
                eSignature: null,
            },
            filledFormPdfs: [],
            requestStatus: null,
            errorMsg: null,
            PDFDownloadLink: '',
            responseCourtInfo: null,
        },
        send: {
            email: null,
            requestStatus: null,
            errorMsg: null,
        },
    }),
};

const parseEvictions = (evictions) => {
    const parsedRecords = {};

    evictions.forEach((eviction, i) => {
        const { caseNumber } = eviction;
        parsedRecords[caseNumber] = {
            ...eviction,
            // default to false until the user has had a chance to confirm or deny
            isConfirmed: false,
            index: i,
        };
    });

    return parsedRecords;
};

const EvictionReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SEARCH_RECORDS_SUCCESS:
        return {
            ...state,
            records: parseEvictions(payload.ccap.eviction),
        };
    case EvictionTypes.SET_EVICTION_BASIC_INFO:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    ...payload.formData,
                },
            },
        };
    case EvictionTypes.SET_EVICTION_CONFIRM:
        return {
            ...state,
            records: {
                ...state.records,
                [payload.caseNumber]: {
                    ...state.records[payload.caseNumber],
                    isConfirmed: payload.isConfirmed,
                },
            },
        };
    case EvictionTypes.SET_EVICTION_ESIGNATURE:
        return {
            ...state,
            form: {
                ...state.form,
                data: {
                    ...state.form.data,
                    eSignature: payload.eSignature,
                },
            },
        };
    case EvictionTypes.SET_EVICTION_SEND_TO_EMAIL:
        return {
            ...state,
            send: {
                ...state.send,
                email: payload.email,
            },
        };
    case EvictionTypes.POST_EVICTION_FORM_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case EvictionTypes.POST_EVICTION_FORM_SUCCESS:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.SUCCESS,
                filledFormPdfs: payload.filledFormPdfs,
            },
        };
    case EvictionTypes.POST_EVICTION_FORM_ERROR:
        return {
            ...state,
            form: {
                ...state.form,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case EvictionTypes.SEND_EVICTION_FORM_BEGIN:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.ACTIVE,
            },
        };
    case EvictionTypes.POST_EVICTION_FORM_DOWNLOAD_SUCCESS: {
        const key = Object.keys(payload.responseCourtInfo)[0];
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: payload.PDFDownloadLink,
                responseCourtInfo: {
                    title: 'Clerk of Courts',
                    ...payload.responseCourtInfo[key],
                },
            },
        };
    }
    case EvictionTypes.POST_EVICTION_FORM_DOWNLOAD_ERROR:
    case EvictionTypes.POST_EVICTION_FORM_DOWNLOAD_BEGIN:
        return {
            ...state,
            form: {
                ...state.form,
                PDFDownloadLink: '',
                responseCourtInfo: null,
            },
        };
    case EvictionTypes.SEND_EVICTION_FORM_SUCCESS:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.SUCCESS,
            },
        };
    case EvictionTypes.SEND_EVICTION_FORM_ERROR:
        return {
            ...state,
            send: {
                ...state.send,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case SessionTypes.CLEAR_SESSION:
        return internals.initial();

    default:
        // do nothing
    }
    return state;
};

export default EvictionReducer;
