import { createSelector } from 'reselect';

const getCriminalRecordState = state => state.crimrecord;

const getCriminalRecordFormState = createSelector(
    getCriminalRecordState,
    state => state.form,
);

export const getCriminalRecordFormData = createSelector(
    getCriminalRecordFormState,
    formState => formState.data,
);

export const getFoundCCAPRecords = createSelector(
    getCriminalRecordState,
    state => Object.values(state.ccap.records),
);

export const getConfirmedCCAPRecords = createSelector(
    getFoundCCAPRecords,
    foundRecords => foundRecords.filter(record => record.isConfirmed),
);

export const getConfirmedRemovableCCAPRecords = createSelector(
    getFoundCCAPRecords,
    foundRecords => foundRecords.filter(record => record.isConfirmed && record.removable),
);

export const getCountCCAPRecords = createSelector(
    getFoundCCAPRecords,
    foundRecords => foundRecords.length,
);

export const getFilledCCAPFormPdfs = createSelector(
    getCriminalRecordFormState,
    formState => formState.filledFormPdfs.ccap || [],
);

export const getDojSearchStatus = createSelector(
    getCriminalRecordState,
    state => state.doj.requestStatus,
);

export const getDojPollingTimer = createSelector(
    getCriminalRecordState,
    state => state.doj.pollingTimer,
);

export const getFoundDOJRecords = createSelector(
    getCriminalRecordState,
    state => Object.values(state.doj.records),
);

export const getConfirmedDOJRecords = createSelector(
    getFoundDOJRecords,
    foundRecords => foundRecords.filter(record => record.isConfirmed),
);

export const getConfirmedRemovableDOJRecords = createSelector(
    getFoundDOJRecords,
    foundRecords => foundRecords.filter(record => record.isConfirmed && record.removable),
);

export const getCountDOJRecords = createSelector(
    getFoundDOJRecords,
    foundRecords => foundRecords.length,
);

export const getFilledDOJFormPdfs = createSelector(
    getCriminalRecordFormState,
    formState => formState.filledFormPdfs.doj || [],
);

export const getFilledFormPdfs = createSelector(
    getFilledCCAPFormPdfs,
    getFilledDOJFormPdfs,
    (ccapForms, dojForms) => ccapForms.concat(dojForms),
);

export const getPDFDownloadLink = createSelector(
    getCriminalRecordFormState,
    formState => formState.PDFDownloadLink,
);

export const getResponseCourtInfo = createSelector(
    getCriminalRecordFormState,
    formState => formState.responseCourtInfo,
);

export const getEmailToSendForms = createSelector(
    getCriminalRecordState,
    state => state.send.email,
);
