const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    POST_CSOC_FORM_BEGIN: true,
    POST_CSOC_FORM_DOWNLOAD_BEGIN: true,
    POST_CSOC_FORM_DOWNLOAD_ERROR: true,
    POST_CSOC_FORM_DOWNLOAD_SUCCESS: true,
    POST_CSOC_FORM_ERROR: true,
    POST_CSOC_FORM_SUCCESS: true,
    SET_CSOC_BASIC_INFO: true,
    SET_CSOC_ESIGNATURE: true,
    SET_USER_SUPPORT_ORDERS: true,
});
