import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { createTheme, makeStyles } from '@material-ui/core/styles';

import Colors from '../../styles/colors';
import { startOver } from '../../actions/session';

import HeaderImg from './images/wrong-number-icon.png';

import useI18n from '../../utils/use-i18n';
import ContentEnglish from './i18n/en';
import ContentSpanish from './i18n/es';
import { sendCustomEvent } from '../../utils/ga';

const content = {
    en: ContentEnglish,
    es: ContentSpanish,
};

const theme = createTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistency
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
});
const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100vh',
        backgroundColor: Colors.pageBackground,
    },
    containerWrapper: {
        maxWidth: 700,
        paddingTop: 60,
    },
    sectionWrapper: {
        backgroundColor: 'white',
        padding: 45,
        marginBottom: 100,
        borderRadius: 8,
        [theme.breakpoints.down('md')]: {
            padding: 20,
            paddingBottom: 45,
            marginRight: 0,
            marginLeft: 0,
        },
    },
    headerImg: {
        width: 100,
        paddingBottom: 15,
    },
    title: {
        fontWeight: 700,
        fontSize: '2.125rem',
        fontFamily: 'sofia-pro, sans-serif;',
        letterSpacing: -0.5,
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.875rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.6875rem',
        },
    },
    description: {
        lineHeight: '1.3em',
        fontSize: '1.125rem',
        fontFamily: 'sofia-pro, sans-serif;',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.0625rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    blueButton: {
        textTransform: 'none',
        fontFamily: 'sofia-pro, sans-serif;',
        fontWeight: 700,
        fontSize: '1.125rem',
        minHeight: 50,
        borderRadius: 5,
        lineHeight: 1,
        display: 'block',
        minWidth: 275,
        backgroundColor: Colors.cobaltBright,
        color: 'white',
        marginBottom: 15,
        marginTop: 30,
        boxShadow: '0 5px 15px 0 rgba(0,0,0,0.2)',

        '&:hover, &:focus:hover': {
            backgroundColor: Colors.cobalt,
        },
        '&:active': {
            backgroundColor: Colors.cobalt,
        },
        '&:focus': {
            backgroundColor: Colors.cobaltBright,
        },
        '&:disabled': {
            color: Colors.white,
        },

        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
            fontSize: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.0625rem',
            minHeight: 44,
        },
    },
    blueButtonRippleChildPulsate: {
        backgroundColor: `rgba(${Colors.cobaltDkRGB},1)`,
    },
    buttonLabel: {
        position: 'relative',
        zIndex: 1,
    },
});

const ErrorBoundary = ({ error, componentStack }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleStartOver = useCallback(() => {
        dispatch(startOver());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            sendCustomEvent('error-boundary', { error: error.toString(), componentStack });
        }
    }, [error, componentStack]);

    return (
        <main className={classes.root}>
            <div className={`container ${classes.containerWrapper}`}>
                <div className={`row ${classes.sectionWrapper}`}>
                    <div className="col">
                        <img src={HeaderImg} alt="" className={classes.headerImg} />
                        <Typography variant="h1" className={classes.title}>{useI18n('title', content)}</Typography>
                        <Typography variant="body2" className={classes.description}>{useI18n('description', content)}</Typography>

                        <div className="row">
                            <div className="col d-flex align-items-center flex-column">
                                <Button
                                    className={`${classes.blueButton}`}
                                    classes={{
                                        label: classes.buttonLabel,
                                    }}
                                    TouchRippleProps={{
                                        classes: {
                                            childPulsate: classes.blueButtonRippleChildPulsate,
                                        },
                                    }}
                                    onClick={handleStartOver}
                                >
                                    {useI18n('startOverBtn', content)}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

ErrorBoundary.propTypes = {
    error: PropTypes.any,
    componentStack: PropTypes.any,
};

ErrorBoundary.defaultProps = {
    error: [],
    componentStack: '',
};

export default ErrorBoundary;
