import SessionTypes from '../action-types/session';

const internals = {
    initial: () => ({
        timer: null,
        privacyAccepted: false,
        reCaptchaKeyAccepted: null,
    }),
};

const SessionReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case SessionTypes.VERIFY_RECAPTCHA_SUCCESS:
        return {
            ...state,
            reCaptchaKeyAccepted: payload,
        };
    case SessionTypes.VERIFY_RECAPTCHA_BEGIN:
        return {
            ...state,
            reCaptchaKeyAccepted: null,
        };
    case SessionTypes.VERIFY_RECAPTCHA_ERROR:
        return {
            ...state,
            reCaptchaKeyAccepted: false,
        };
    case SessionTypes.SET_ACCEPT_PRIVACY:
        return {
            ...state,
            privacyAccepted: payload,
        };
    case SessionTypes.SET_SESSION_TIMER:
        return {
            ...state,
            timer: payload.timer,
        };
    case SessionTypes.CLEAR_SESSION_TIMER:
        return {
            ...state,
            timer: null,
        };
    case SessionTypes.CLEAR_SESSION:
        return {
            ...internals.initial(),
            privacyAccepted: state.privacyAccepted,
        };

    default:
        // do nothing
    }
    return state;
};

export default SessionReducer;
