import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Auth from './auth';
import User from './user';
import I18n from './i18n';
import CriminalRecord from './crimrecord';
import Eviction from './eviction';
import EATA from './eata';
import HealthAdv from './health-adv';
import Session from './session';
import DOT from './dot';
import SupportOrders from './supportorders';
import DebtCollection from './debt-collection';

const Reducers = combineReducers({
    session: Session,
    auth: Auth,
    user: User,
    i18n: I18n,
    routing: routerReducer,
    crimrecord: CriminalRecord,
    debtCollection: DebtCollection,
    eviction: Eviction,
    eata: EATA,
    healthAdv: HealthAdv,
    dot: DOT,
    supportOrders: SupportOrders,
});

export default Reducers;
