import WebClient from '../utils/web-client';
import DebtCollectionTypes from '../action-types/debt-collection';
import {
    getDebtCollectionFormData,
    getFilledFormPdfs,
    getEmailToSendForms,
    getFoundDebtCollections,
    getHarassmentInfo,
} from '../selectors/debt-collection';

import {
    getUserId, getUserFormData, getUserFullName, getUserDOB,
} from '../selectors/user';
import { getLanguage } from '../selectors/i18n';
import { getFormattedDate } from '../utils/date-utils';

const COLLECTION_FORM_ID = 6; // TODO: get actual form ID

export const setDebtCollectionBasicInfo = formData => ({
    type: DebtCollectionTypes.SET_DEBT_COLLECTION_BASIC_INFO,
    payload: { formData },
});

// IF we need to confirm is collections
const setDebtCollectionConfirmDone = (caseNumber, isConfirmed) => ({
    type: DebtCollectionTypes.SET_DEBT_COLLECTION_CONFIRM,
    payload: {
        caseNumber,
        isConfirmed,
    },
});

export const setDebtCollectionConfirm = (currentRecord, isConfirmed) => (
    async (dispatch, getState) => {
        dispatch(setDebtCollectionConfirmDone(currentRecord.caseNumber, isConfirmed));
        const user = getUserFormData(getState());
        const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            dob: user.dob,
            county: currentRecord.county,
            source: 'ccap', // collections can only come from CCAP
            isUser: isConfirmed,
            caseNumber: currentRecord.caseNumber,
        };
        try {
            // assuming we want to save these?
            await WebClient.post('/users/search/results', payload);
            // eslint-disable-next-line no-empty
        } catch (error) { } // currently swallowing. good idea?
    }
);


// Assume users will want to download offered form.
const postDebtCollectionFormDownloadBegin = () => ({
    type: DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_DOWNLOAD_BEGIN,
});
const postDebtCollectionFormDownloadSuccess = (PDFDownloadLink, responseCourtInfo) => ({
    type: DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_DOWNLOAD_SUCCESS,
    payload: { PDFDownloadLink, responseCourtInfo },
});
const postDebtCollectionFormDownloadError = errorMsg => ({
    type: DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_DOWNLOAD_ERROR,
    payload: { errorMsg },
});

const postDebtCollectionFormDownload = () => (
    async (dispatch, getState) => {
        const filledForms = getFilledFormPdfs(getState());
        const payload = {
            ids: filledForms.map(f => f.id),
        };

        dispatch(postDebtCollectionFormDownloadBegin());

        try {
            const response = await WebClient.post('/forms/download', payload);
            dispatch(postDebtCollectionFormDownloadSuccess(response.data.url, response.data.courts));
        } catch (error) {
            dispatch(postDebtCollectionFormDownloadError(error));
        }
    }
);

const postDebtCollectionFormBegin = () => ({
    type: DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_BEGIN,
});

const postDebtCollectionFormSuccess = filledFormPdfs => ({
    type: DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_SUCCESS,
    payload: { filledFormPdfs },
});

const postDebtCollectionFormError = errorMsg => ({
    type: DebtCollectionTypes.POST_DEBT_COLLECTION_FORM_ERROR,
    payload: { errorMsg },
});

export const setDebtCollectionESignature = eSignature => ({
    type: DebtCollectionTypes.SET_DEBT_COLLECTION_ESIGNATURE,
    payload: { eSignature },
});

export const setHarassmentInfo = data => ({
    type: DebtCollectionTypes.SET_HARASSMENT_INFO,
    payload: { data },
});

export const sendHarassmentLetter = (email, onSuccess, onError) => async (dispatch, getState) => {
    const userFormData = getHarassmentInfo(getState());

    try {
        const addressesTo = [{
            name: userFormData.agencyName,
            line1: userFormData.addressLine1,
            line2: userFormData.address2,
            city: userFormData.city,
            state: userFormData.state,
            zip: userFormData.zip,
        }];

        await WebClient.post('/forms/filled/debt-collection/send', {
            ids: [userFormData.id],
            email,
            addressesTo,
            addressFrom: null,
        });

        dispatch(setHarassmentInfo({ successs: true }));

        onSuccess();
    } catch (err) {
        onError();
    }
};

export const postSendTexts = async (caseNumber, phoneNumber, onSuccess, onError) => {
    try {
        await WebClient.post('/users/case-updates', {
            caseNumber,
            phoneNumber,
        });
        onSuccess();
    } catch (err) {
        onError();
    }
};

export const postStopHarassmentLetter = (onSuccess, onError) => async (dispatch, getState) => {
    try {
        const userFormData = getHarassmentInfo(getState());
        const userId = getUserId(getState());
        const userName = getUserFullName(getState());
        const dob = getUserDOB(getState());

        const payload = {
            formData: {
                ...userFormData,
                name: userName,
                userId,
                dob,
            },
        };

        const response = await WebClient.post('/forms/debt/harassment', payload);
        dispatch(setHarassmentInfo(response.data));

        onSuccess();
    } catch (err) {
        onError();
    }
};

// Assuming fields are similar to eviction
export const postDebtCollectionForm = (caseNumber, onSuccess, onError) => (
    async (dispatch, getState) => {
        const userFormData = getDebtCollectionFormData(getState());
        const userId = getUserId(getState());
        const collections = getFoundDebtCollections(getState());

        dispatch(postDebtCollectionFormBegin());

        const contestedReasonsResponses = {
            'dont-recognize': 'I do not recognize the debt.',
            'incorrect-info': 'It may be my debt, but some of the information is wrong.',
            'not-your-debt': 'It is not my debt.',
            bankruptcy: 'The debt was cleared through bankruptcy.',
            'too-old': 'The debt is too old to collect.',
        };

        try {
            const currentCase = collections.filter(record => record.caseNumber === caseNumber);
            const filledForms = await Promise.all(
                currentCase.map(async (record) => {
                    const plaintiff = record.parties.find(party => party.type.descr === 'Plaintiff');
                    const contestedReasons = (Array.isArray(userFormData.reasons) ? userFormData.reasons : [userFormData.reasons])
                        .map(value => contestedReasonsResponses[value] || '')
                        .join(' ');
                    const payload = {
                        formData: {
                            0: {
                                county: record.county,
                                caseNumber: record.caseNumber,
                                plaintiffName: plaintiff.name.first ? `${plaintiff.name.first} ${plaintiff.name.last}` : plaintiff.name.last,
                                plaintiffAddress: plaintiff.address.addr.streetLine1,
                                plaintiffAddress2: plaintiff.address.addr.streetLine2,
                                plaintiffCity: plaintiff.address.addr.city,
                                plaintiffState: plaintiff.address.addr.state,
                                plaintiffZip: plaintiff.address.addr.zip,
                                petitioner: `${userFormData.defendantFirstName} ${userFormData.defendantLastName}`,
                                defendantName: `${userFormData.defendantFirstName} ${userFormData.defendantLastName}`,
                                defendantAddress: userFormData.defendantAddress,
                                defendantAddress2: userFormData.defendantAddress2,
                                defendantCity: userFormData.defendantCity,
                                defendantState: userFormData.defendantState,
                                defendantZip: userFormData.defendantZip,
                                isContested: userFormData.answer === 'disagree',
                                isNotContested: userFormData.answer === 'no_contest',
                                contestedReason: `${contestedReasons} ${(userFormData.reason || '')}`,
                                seeAttached: false,
                            },
                            1: {
                                noCounterclaim: true,
                                date: getFormattedDate(new Date(), 'MM/dd/yyyy'),
                                signature: userFormData.eSignature || userFormData.signature,
                                signatureAddress: `${userFormData.defendantAddress}, ${userFormData.defendantCity}, ${userFormData.defendantState} ${userFormData.defendantZip}`,
                                phoneNumber: userFormData.phone,
                                emailAddress: userFormData.email,
                                signatureName: `${userFormData.defendantFirstName} ${userFormData.defendantLastName}`,
                            },
                            signature: userFormData.eSignature || userFormData.signature,
                            county: record.county,
                            caseNumber: record.caseNumber,
                            userId,
                            address1: userFormData.defendantAddress,
                            city: userFormData.defendantCity,
                            state: userFormData.defendantState,
                            zip: userFormData.defendantZip,
                        },
                    };
                    const response = await WebClient.post(`/forms/waiver/${COLLECTION_FORM_ID}/fill`, payload);

                    return {
                        ...response.data,
                        caseNumber: record.caseNumber,
                        createdAt: new Date(),
                        charge: 'Collection',
                        type: 'debtCollection',
                    };
                }),
            );

            dispatch(postDebtCollectionFormSuccess(filledForms));
            dispatch(postDebtCollectionFormDownload());
            onSuccess();
        } catch (error) {
            dispatch(postDebtCollectionFormError(error));
            onError();
        }
    }
);

const setCourts = courts => ({
    type: DebtCollectionTypes.SET_COURTS,
    payload: { courts },
});

export const getCourts = () => (
    async (dispatch) => {
        const courts = await WebClient.get('/courts');
        dispatch(setCourts(courts.data));
    }
);

// Only if we are sending the form(s)
const sendDebtCollectionFormBegin = () => ({
    type: DebtCollectionTypes.SEND_DEBT_COLLECTION_FORM_BEGIN,
});

const sendDebtCollectionFormSuccess = () => ({
    type: DebtCollectionTypes.SEND_DEBT_COLLECTION_FORM_SUCCESS,
});

const sendDebtCollectionFormError = errorMsg => ({
    type: DebtCollectionTypes.SEND_DEBT_COLLECTION_FORM_ERROR,
    payload: { errorMsg },
});

export const sendDebtCollectionForm = (onSuccess, onError) => (
    async (dispatch, getState) => {
        const email = getEmailToSendForms(getState());
        const filledForms = getFilledFormPdfs(getState());
        const language = getLanguage(getState());

        dispatch(sendDebtCollectionFormBegin());

        try {
            const payload = {
                ids: filledForms.map(f => f.id),
                email,
                language,
            };
            await WebClient.post('/forms/filled/send', payload);
            dispatch(sendDebtCollectionFormSuccess());
            onSuccess();
        } catch (error) {
            dispatch(sendDebtCollectionFormError(error));
            onError();
        }
    }
);
