const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    CLEAR_SESSION: true,
    SET_SESSION_TIMER: true,
    CLEAR_SESSION_TIMER: true,
    SET_ACCEPT_PRIVACY: true,
    VERIFY_RECAPTCHA_BEGIN: true,
    VERIFY_RECAPTCHA_SUCCESS: true,
    VERIFY_RECAPTCHA_ERROR: true,
});
