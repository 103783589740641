import KeyMirror from 'keymirror';

import HealthAdvTypes from '../../action-types/health-adv';
import SessionTypes from '../../action-types/session';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        answers: {},
        requestStatus: null,
        errorMsg: null,
    }),
};

const HealthAdvReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case HealthAdvTypes.SAVE_ANSWERS:
        return {
            ...state,
            answers: {
                ...state.answers,
                [payload.stepNo]: payload.answers,
            },
        };
    case HealthAdvTypes.SEND_HEALTH_ADV_REQUEST_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
        };
    case HealthAdvTypes.SEND_HEALTH_ADV_REQUEST_SUCCESS:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case HealthAdvTypes.SEND_HEALTH_ADV_REQUEST_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case SessionTypes.CLEAR_SESSION:
        return internals.initial();
    default:
        // do nothing
    }
    return state;
};

export default HealthAdvReducer;
